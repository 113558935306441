import React, { useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import $ from "jquery";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";

import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Services from "./pages/Services/Services";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import Customers from "./pages/Customers/Customers";
import Partners from "./pages/Partners/Partners";
import Contacts from "./pages/Contacts/Contacts";
import Feedback from "./pages/Feedback/Feedback";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Project/Project";
import News from "./pages/News/News";
import Equipments from "./pages/Products/Equipments";
import EquipmentList from "./pages/Products/EquipmentsList";
import Products from "./pages/Products/Products";
import Product from "./pages/Products/Product";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import NewsPost from "./pages/News/NewsPost";
import NotWorking from "./pages/WebPageNotWorking";
import Сertificates from "./pages/Сertificates/Сertificates";
import Vacancies from "./pages/Vacancies/Vacancies";
import Management from "./pages/Management/Management";

function App(props) {
  let location = useLocation();

  if (location.pathname !== "/") $("body").css("overflow", "overlay");

  return (
    <>
      <Header />
      <Routes>
        {/* <Route exact path="" element={<NotWorking />} /> */}
        <Route exact path="" element={<Home state={props.state} />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route
          exact
          path="/services"
          element={
            <Services
              stateServices={props.state.stateServices}
              setStateServices={props.state.setStateServices}
            />
          }
        />
        <Route
          exact
          path="/services/:link"
          element={
            <ServicesPage
              stateServices={props.state.stateServices}
              setStateServices={props.state.setStateServices}
            />
          }
        />
        <Route exact path="/customers" element={<Customers />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/contacts" element={<Contacts />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/news" element={<News state={props.state} />} />
        <Route exact path="/certificates" element={<Сertificates />} />
        <Route exact path="/vacancies" element={<Vacancies />} />
        <Route exact path="/management" element={<Management />} />
        <Route
          exact
          path="/news/:id"
          element={<NewsPost state={props.state} />}
        />
        <Route
          exact
          path="/equipments"
          element={<Equipments state={props.state} />}
        />
        <Route
          exact
          path="/equipments/:search"
          element={<Equipments state={props.state} />}
        />
        <Route
          exact
          path="/equipment-list/:category"
          element={<EquipmentList state={props.state} />}
        />
        <Route
          exact
          path="/products/:category/:subcategory"
          element={<Products state={props.state} />}
        />
        <Route
          exact
          path="/product/:category/:subcategory/:productLink"
          element={<Product product={props.state.product} />}
        />
        <Route exact path="/admin-panel" element={<AdminPanel />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
