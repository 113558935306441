import React, { useEffect } from "react";
import styled from "styled-components";

const VacanciesStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #FFFFFF; */

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;
    padding: 40px 0px;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  .title {
    font-size: 40px;
    font-weight: bold;
  }

  .vacancies {
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    gap: 40px;

    .vacancy {
      display: flex;
      flex-direction: column;
      font-family: "Montserrat", sans-serif;
      background-color: rgb(252, 252, 252);
      border-radius: 0.45rem;
      border: 1px solid lightgray;

      h1 {
        font-size: 28px;
        padding: 20px;
        font-weight: bold;
        color: #ffc729;
        line-height: 150%;
      }
      hr {
        height: 1px;
        width: 100%;
        background-color: #d3d3d372;
      }

      .vacancy-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        div {
          display: flex;
          flex-direction: column;
          gap: 10px;
          p {
            font-weight: bold;
          }
          h3 {
            font-weight: bold;
            font-size: 16px;
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 0px 20px;
            li {
              position: relative;
              text-align: justify;
              list-style: none;
              line-height: 130%;

              ::before {
                content: "";
                background-color: #abb2ba;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                position: absolute;
                left: -20px;
                top: 5px;
              }
            }
          }
        }
      }
      a {
        margin-top: 10px;
        width: fit-content;
        padding: 14px 20px;
        color: black;
        font-weight: bold;
        border: 1px solid black;
        border-radius: 0.43rem;
        transition: all 0.3s ease;

        :hover {
          background-color: #ffc729;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .title {
      font-size: 35px;
    }
    .vacancies {
      .vacancy {
        h1 {
          font-size: 25px;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .title {
      font-size: 30px;
    }
    .vacancies {
      .vacancy {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: 479.98px) {
    .title {
      font-size: 30px;
    }
    .vacancies {
      padding: 20px 0px;
      .vacancy {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
`;

export default function Vacancies() {
  let vacancies = [{}, {}];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <VacanciesStyles>
      <div className="container">
        <h1 className="title">Вакансии</h1>
        <div className="vacancies">
          <div className="vacancy">
            <h1>Менеджер проектных продаж</h1>
            <hr />
            <div className="vacancy-body">
              <div className="responsibilities">
                <h3>Обязанности:</h3>
                <ul>
                  <li>Привлечение новых ключевых клиентов;</li>
                  <li>
                    Коммуникация и организация деловых переговоров с
                    потенциальными клиентами;
                  </li>
                  <li>
                    Сбор и подготовка необходимых документов для подачи заявок
                    на участие в конкурсах, аукционах, запросах предложений,
                    котировках, запросах цен в рамках ЕОСЗ ГК Росатом, 44-ФЗ,
                    223-ФЗ (Знание нюансов в подготовке пакета документов
                    Роснефть, Газпром, Россети, Росатом, Лукойл, ЗАРУБЕЖНЕФТЬ);
                  </li>
                  <li>Контроль сроков подачи и рассмотрения заявок;</li>
                  <li>
                    Контроль движения денежных средств на площадках (обеспечения
                    аукционов и контрактов);
                  </li>
                  <li>
                    Отслеживание протоколов, приглашений на переторжки,
                    дозапросов документов;
                  </li>
                  <li>
                    Взаимодействие с организаторами/заказчиками торгов по
                    вопросам, касающимся закупочных процедур;
                  </li>
                  <li>Ведение переторжек/процедур уторговывания, аукционов;</li>
                  <li>
                    Работа со смежными отделами для реализации процедур:
                    технические вопросы по закупкам, сбор бухгалтерских
                    документов, подготовка документов по опыту поставок;
                  </li>
                  <li>
                    Ведение установленной отчетности по конкурсным процедурам;
                  </li>
                  <li>Заключение и сопровождение договоров;</li>
                  <li>Работа с первичной документацией;</li>
                  <li>
                    Ведение реестров «проблемных» вопросов и контроль за их
                    решением.
                  </li>
                  <li>Работа в CRM системе;</li>
                  <li>
                    Написание рекламаций, официальных писем, составление жалоб в
                    УФАС и ЦАК.
                  </li>
                </ul>
              </div>
              <div className="requirements">
                <h3>Требования:</h3>
                <ul>
                  <li>
                    Высокая социальная активность, обширный круг знакомств,
                    харизма, коммуникабельность, настойчивость, навыки делового
                    общения и ведения переговоров;
                  </li>
                  <li>
                    Опыт проектных продаж! (аналогичный опыт в сфере продаж
                    электрооборудования (кабельно-проводниковая продукция,
                    щитовое, высоковольтное, низковольтное оборудование,
                    светотехника));
                  </li>
                  <li>
                    Опыт работы на электронных тендерных площадках (ТЭК Торг,
                    ЭТП ГПБ, B2B, Сбербанк-АСТ и другие);
                  </li>
                  <li>
                    Знание компьютерных программ (1С, Word, Excel, Outlook);
                  </li>
                  <li>Высшее образование.</li>
                </ul>
              </div>
              <div className="conditions">
                <h3>Условия:</h3>
                <ul>
                  <li>
                    Оклад (обсуждается индивидуально с успешным кандидатом),
                    квартальные премии при выполнении плана и показателей KPI;
                  </li>
                  <li>Возможность финансового и карьерного роста;</li>
                  <li>
                    Ежегодный оплачиваемый отпуск, график работы 5/2, с 09:00 до
                    18:00, по пятницам-до 17:00;
                  </li>
                  <li>
                    Офис расположен в центре, метро в шаговой доступности от
                    офиса (5 минут пешком от м. Цветной Бульвар, м.
                    Сухаревская,);
                  </li>
                  <li>
                    Комфортные условия труда: множество ресторанов и кафе в
                    шаговой доступности от офиса, кухня на территории офиса
                    (СВЧ-печь, зерновая кофеварка, холодильник, кулер).
                  </li>
                </ul>
                <p>Кандидаты без аналогичного опыта не рассматриваются.</p>
              </div>
              <a href="mailto:info@arcticcon.ru">Откликнуться на вакансию</a>
            </div>
          </div>
          <div className="vacancy">
            <h1>Специалист отдела комплексного снабжения (ВЭД)</h1>
            <hr />
            <div className="vacancy-body">
              <div className="responsibilities">
                <h3>Обязанности:</h3>
                <ul>
                  <li>
                    Ведение переговоров с производителями электротехнического
                    оборудования;
                  </li>
                  <li>Оптимизация предложений (цена, качество, срок);</li>
                  <li>
                    Закупка электротехнического оборудования (размещение на
                    производстве, контроль отгрузки);
                  </li>
                  <li>Работа по рекламациям;</li>
                  <li>Контроль за исполнением договорных обязательств;</li>
                  <li>
                    Работа с внутренней базой поставщиков, а также привлечение
                    новых поставщиков/производителей с наилучшими условиями
                    поставки или отсрочкой платежа;
                  </li>
                  <li>Работа в CRM системе;</li>
                  <li>Техническая аналитика закупочных процедур;</li>
                  <li>
                    Проработка функциональных аналогов оборудования и материалов
                    по ТЗ Заказчика;
                  </li>
                  <li>
                    Защита проектов у производителей (получение эксклюзивных
                    условий).
                  </li>
                </ul>
              </div>
              <div className="requirements">
                <h3>Требования:</h3>
                <ul>
                  <li>
                    Обязательное знание рынка электротехнического оборудования
                    и, в частности, светотехнической продукции, взрывозащитного
                    и низковольтного оборудования, коммутационного оборудования,
                    сетевого и телекоммуникационного оборудования, оборудования
                    для встроенных систем управления, источники бесперебойного
                    питания, КИПиА, энергогенерирующего оборудования и ЗИП с
                    конкретными примерами поставок по своему направлению за 2022
                    год;
                  </li>
                  <li>
                    Опыт работы в сфере закупок электротехнического оборудования
                    от 2-х лет (где основными клиентами являются энергетический
                    и нефтегазовый сектор). Особым преимуществом кандидата будет
                    наличие опыта по снабжению тендерных/проектных продаж и
                    поставок;
                  </li>
                  <li>Высшее техническое образование как преимущество;</li>
                  <li>
                    Работа в программах Excel, Word, Outlook, желательно знание
                    программы 1С 8.2 (контроль текущих заказов, складских
                    остатков);
                  </li>
                  <li>
                    Прямые отношения с производителями зарубежного оборудования;
                  </li>
                  <li>
                    Коммуникация с производителями, вендорами и дистрибьюторами
                    импортной продукции (в т.ч Siemens, ABB, Schneider Electric,
                    Phoenix Contact, Cisco ,SEW Eurodrive, Rockwell Automation,
                    Endress+Hauser, Emerson, Lenovo, Dell);
                  </li>
                  <li>
                    Опыт формирования и размещения заказов на производстве за
                    границей: США, Европе, Индии, Ближнем Востоке, Китае и т.д.
                    (обязательное условие для рассмотрения кандидатов);
                  </li>
                  <li>
                    Обязательное знание английского языка на уровне не ниже
                    Upper-Intermediate. (Уровень владения языком проверяется
                    тестированием и собеседованием).
                  </li>
                </ul>
              </div>
              <div className="conditions">
                <h3>Условия:</h3>
                <ul>
                  <li>
                    Оклад + квартальная премия (% от реализованных проектов) при
                    выполнении плана и показателей KPI. Условия согласовываются
                    индивидуально с успешным кандидатом;
                  </li>
                  <li>
                    Офис расположен в центре, метро в шаговой доступности от
                    офиса (5 минут пешком от м. Цветной Бульвар);
                  </li>
                  <li>
                    Ежегодный оплачиваемый отпуск, график работы 5/2, с 09:00 до
                    18:00, по пятницам-до 17:00;
                  </li>
                  <li>
                    Комфортные условия труда: множество ресторанов и кафе в
                    шаговой доступности от офиса, кухня на территории офиса
                    (СВЧ-печь, зерновая кофеварка, холодильник, кулер).
                  </li>
                </ul>
              </div>
              <a href="mailto:info@arcticcon.ru">Откликнуться на вакансию</a>
            </div>
          </div>
          <div className="vacancy">
            <h1>Инженер по качеству ( инженер ОТК)</h1>
            <hr />
            <div className="vacancy-body">
              <div className="responsibilities">
                <h3>Обязанности:</h3>
                <ul>
                  <li>Прорисовка эл.принципиальных схем изделия</li>
                  <li>Прорисовка компоновочной схемы изделия</li>
                  <li>Подбор комплектующих (при необходимости замены)</li>
                  <li>
                    Подготовка выходной документации (паспорт изделия,
                    спецификация и т.д.)
                  </li>
                  <li>Сопровождение изделий в производство</li>
                  <li>
                    Консультация сотрудников Компании по данному оборудованию
                  </li>
                </ul>
              </div>
              <div className="requirements">
                <h3>Требования:</h3>
                <ul>
                  <li>
                    Высшее техническое образование (желательно по профилю)
                  </li>
                  <li>Опыт отрисовки принципиальных и однолинейных схем</li>
                  <li>
                    Опыт работы по подготовке документации на НКУ-изделия или
                    подобное оборудование от 1 года
                  </li>
                  <li>Опытный пользователь AutoCAD</li>
                </ul>
              </div>
              <div className="conditions">
                <h3>Условия:</h3>
                <ul>
                  <li>
                    Оклад + квартальная премия (% от реализованных проектов) при
                    выполнении плана и показателей KPI. Условия согласовываются
                    индивидуально с успешным кандидатом;
                  </li>
                  <li>
                    Офис расположен в центре, метро в шаговой доступности от
                    офиса (5 минут пешком от м. Цветной Бульвар);
                  </li>
                  <li>
                    Ежегодный оплачиваемый отпуск, график работы 5/2, с 09:00 до
                    18:00, по пятницам-до 17:00;
                  </li>
                  <li>
                    Комфортные условия труда: множество ресторанов и кафе в
                    шаговой доступности от офиса, кухня на территории офиса
                    (СВЧ-печь, зерновая кофеварка, холодильник, кулер).
                  </li>
                </ul>
              </div>
              <a href="mailto:info@arcticcon.ru">Откликнуться на вакансию</a>
            </div>
          </div>
        </div>
      </div>
    </VacanciesStyles>
  );
}
