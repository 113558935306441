import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import NewsList from "./NewsList";

export default function NewsController({ state }) {
  const [section, setSection] = useState({
    action: "Новости",
  });

  function getSection() {
    switch (section.action) {
      case "Новости":
        return <NewsList state={state} setSection={setSection} />;
      case "Добавление новости":
        return (
          <AddNews
            state={state}
            setSection={setSection}
            postID={section.postID}
          />
        );
      case "Редактирование новости":
        return (
          <EditNews state={state} setSection={setSection} _news={section.news} />
        );
    }
  }

  return getSection();
}
