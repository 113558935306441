import React from "react";
import CertificatesCarousel from "../CertificatesCarousel/CertificatesCarousel";
import { useState, useEffect } from "react";
import {
  getCertificatesStorage,
  addCertificateItem,
} from "../../firebase/Certificates";

export default function CertificatesBlock() {
  const [images, setImages] = useState(null);

  // function importAll(r) {
  //   let images = {};
  //   r.keys().forEach((item, index) => {
  //     images[item.replace("./", "")] = r(item);
  //   });
  //   return images;
  // }

  useEffect(() => {
    if (images == null) {
      getCertificatesStorage().then((list) => {
        let img = [];
        list.forEach((item) => {
          img.push({ img: item });
        });
        setImages(img);
      });
    }
  }, []);

  return (
    <section className="ten">
      <h1>Сертификаты</h1>
      <div className="container">
        <div className="ten__block">
          <div className="ten__block_images">
            {images != null ? <CertificatesCarousel images={images} /> : ""}
          </div>
        </div>
      </div>
      <div className="line">
        <p>
          В данном разделе размещены сертификаты и письма, выданные компании ООО
          "Арктик Энергострой" и подтверждающие ее полномочия, а также
          компетенцию и опыт в сфере комплектации объектов.
        </p>
      </div>
    </section>
  );
}
