import React, { useState, useEffect } from "react";
import arrow from "./../../assets/svg/services/arrow.svg";

import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import ExtraMenu from "../../components/ExtraMenu/ExtraMenu";
import { Markup } from "interweave";

import { getService, getServices } from "../../firebase/Firebase";

const ServicesPageStyles = styled.div`
  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 50px 0px;
  }

  .navigation {
    width: 100%;
    display: flex;
    gap: 50px;

    .path {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        li {
          display: flex;
          align-items: center;
          width: fit-content;
          gap: 10px;

          &:visited {
            color: black;
          }

          img {
            height: 40%;
            width: 25px;
          }

          .button {
            width: 100%;
            font-size: 16px;
            transition: all 0.2s ease;
            color: black;

            .visited,
            .active,
            .focus {
              color: black;
            }
          }

          .this {
            background: none;
            font-weight: 600;

            :hover {
              color: black;
            }
          }
        }
      }
      .back {
        width: fit-content;
        display: flex;
        gap: 10px;
        align-items: center;
        .icon {
          transform: rotate(180deg);
          height: 40%;
          width: 25px;
        }
        &_button {
          font-size: 16px;
          transition: all 0.2s ease;
          color: black;

          .visited,
          .active,
          .focus {
            color: black;
          }
        }
      }
    }
  }

  section {
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    padding: 0px 50px;

    @media (max-width: 767.98px) {
      padding: 0px 20px;
    }

    .block__title {
      h1 {
        font-weight: 700;
        font-size: 35px;
        text-transform: uppercase;

        @media (max-width: 1199.98px) {
          font-weight: 600;
        }

        @media (max-width: 991.98px) {
          font-size: 25px;
        }

        @media (max-width: 767.98px) {
          font-size: 22px;
        }

        @media (max-width: 479.98px) {
          font-size: 20px;
        }
      }
    }

    .block__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      @media (max-width: 1199.98px) {
        &_title {
          font-size: 14px;
        }
        &_data {
          ul li,
          p {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 991.98px) {
        &_title {
          font-size: 12px;
        }
        &_data {
          ul li,
          p {
            font-size: 12px;
          }
        }
      }

      @media (max-width: 767.98px) {
        &_title {
          font-size: 12px;
        }
        &_data {
          ul li,
          p {
            font-size: 12px;
          }
        }
      }

      &_data {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        span {
          text-indent: 2ch;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 5px;
          li {
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
          }
        }
        ul.inside {
          li {
            list-style: circle;
            list-style-position: inside;
          }
        }
        p {
          padding: 5px 0px;
          font-size: 16px;
          font-weight: 400;
          line-height: 130%;
        }
      }

      &_title {
        text-indent: 3ch;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }
`;

export default function ServicesPage({ stateServices, setStateServices }) {
  const [service, setService] = useState(null);

  let { link } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (service == null) {
      if (stateServices.length == 0) {
        getService(link).then((data) => {
          setStateServices([data]);
          setService(data);
        });
      } else {
        setService(stateServices.find((x) => x.link == link));
      }
    }
  }, [link]);

  return (
    <ServicesPageStyles>
      <section>
        <div className="container">
          {service != null ? (
            <div className="block">
              <div className="block__title">
                <h1>{service.name}</h1>
              </div>
              <div className="navigation">
                <div className="path">
                  <ul>
                    <li>
                      <NavLink className="button" to="/">
                        Главная
                      </NavLink>
                      <img className="icon" src={arrow} alt="" />
                    </li>
                    <li>
                      <NavLink className="button" to="/services">
                        Услуги и сервис
                      </NavLink>
                      <img className="icon" src={arrow} alt="" />
                    </li>
                    <li>
                      <button className="button this">{service.name}</button>
                    </li>
                  </ul>
                  <div className="back">
                    <img className="icon" src={arrow} alt="" />
                    <NavLink className="back_button" to="/services">
                      Назад
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="block__info">
                <div className="block__info_title">{service.title}</div>
                {Array.isArray(service.data) ? (
                  service.data.map((paragraph, index) => {
                    return (
                      <p className="block__info_data" key={index}>
                        <Markup content={paragraph} />
                      </p>
                    );
                  })
                ) : (
                  <Markup className="block__info_data" content={service.data} />
                )}
              </div>

              <ExtraMenu></ExtraMenu>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </ServicesPageStyles>
  );
}
