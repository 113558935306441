import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, Button } from "@mui/material";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { addProduct, storage } from "../../../../../firebase/Firebase";
import translitRusEng from "translit-rus-eng";

const AddProductStyles = styled.div`
  .add-product {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 550px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;

      h2 {
        font-size: 15px;
        padding: 10px;
      }
      .projects-list {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .projects {
          background-color: rgba(0, 0, 0, 0.03);
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
      .characters {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .character-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
      }
      .manufacturer {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .controller-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function AddProduct({
  state,
  category,
  subcategory,
  setSection,
}) {
  const [product, setProduct] = useState({
    name: null,
    category: category.name,
    subcategory: subcategory.name,
    img: null,
    purpose: null,
    characters: [],
    conditions: null,
    download: null,
    projects: [],
    link: null,
    manufacturer: [],
    creator: null,
  });
  const [imageUpload, setImageUpload] = useState(null);

  const handleChange = (value) => {
    setProduct((product) => ({
      ...product,
      ...value,
    }));
  };

  function getCharacters() {
    return (
      <div className="characters">
        <h2>Основные технические характеристики:</h2>
        {product.characters.map((character, index) => {
          return (
            <div className="character-row" key={index}>
              <TextField
                fullWidth
                label="Поле"
                defaultValue={character.key}
                variant="outlined"
                onChange={(e) => {
                  product.characters[index].key = e.target.value;
                  let newList = [...product.characters];
                  handleChange({ characters: newList });
                }}
              />
              <TextField
                fullWidth
                label="Значение"
                defaultValue={character.value}
                variant="outlined"
                multiline
                rows={3}
                onChange={(e) => {
                  product.characters[index].value = e.target.value;
                  let newList = [...product.characters];
                  handleChange({ characters: newList });
                }}
              />
            </div>
          );
        })}
        <div className="controller-buttons">
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              if (product.characters.length == 0) return;
              let list = [...product.characters];
              list.pop();
              handleChange({ characters: list });
            }}
            variant="contained"
          >
            -
          </Button>
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              let list = [...product.characters];
              list.push({ key: "", value: "" });
              handleChange({ characters: list });
            }}
            variant="contained"
          >
            +
          </Button>
        </div>
      </div>
    );
  }

  function getProjects() {
    return (
      <div className="projects-list">
        <h2>Проекты:</h2>
        {product.projects.map((project, index) => {
          return (
            <div className="projects" key={index}>
              <TextField
                fullWidth
                label="Производитель"
                defaultValue={project.manufacturer}
                variant="outlined"
                onChange={(e) => {
                  product.projects[index].manufacturer = e.target.value;
                  let newList = [...product.projects];
                  handleChange({ projects: newList });
                }}
              />
              <TextField
                fullWidth
                label="Продукты"
                defaultValue={project.products}
                variant="outlined"
                multiline
                rows={4}
                onChange={(e) => {
                  product.projects[index].products = e.target.value;
                  let newList = [...product.projects];
                  handleChange({ projects: newList });
                }}
              />
              <TextField
                fullWidth
                label="Заказчик"
                defaultValue={project.customer}
                variant="outlined"
                onChange={(e) => {
                  product.projects[index].customer = e.target.value;
                  let newList = [...product.projects];
                  handleChange({ projects: newList });
                }}
              />
            </div>
          );
        })}
        <div className="controller-buttons">
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              if (product.projects.length == 0) return;
              let list = [...product.projects];
              list.pop();
              handleChange({ projects: list });
            }}
            variant="contained"
          >
            -
          </Button>
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              let list = [...product.projects];
              list.push({ manufacturer: "", products: "", customer: "" });
              handleChange({ projects: list });
            }}
            variant="contained"
          >
            +
          </Button>
        </div>
      </div>
    );
  }

  function getManufacturer() {
    return (
      <div className="manufacturer">
        <h2>Описание производителя:</h2>
        {product.manufacturer.map((manufacturer, index) => {
          return (
            <TextField
              key={index}
              label="Параграф"
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) => {
                product.manufacturer[index] = e.target.value;
                let newList = [...product.manufacturer];
                handleChange({ manufacturer: newList });
              }}
            />
          );
        })}
        <div className="controller-buttons">
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              if (product.manufacturer.length == 0) return;
              let list = [...product.manufacturer];
              list.pop();
              handleChange({ manufacturer: list });
            }}
            variant="contained"
          >
            -
          </Button>
          <Button
            sx={{ maxWidth: "30px" }}
            onClick={() => {
              let list = [...product.manufacturer];
              list.push("");
              handleChange({ manufacturer: list });
            }}
            variant="contained"
          >
            +
          </Button>
        </div>
      </div>
    );
  }

  function uploadFile() {
    if (imageUpload === null || product.name == null) return;
    const imageRef = ref(storage, `images/products/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        product.img = url;
        var newLink = product.name.replaceAll("/", "_").replaceAll(" ", "_");
        var link = translitRusEng(newLink, {
          slug: true,
        });
        product.link = link;

        addProduct(product).then((id) => {
          product.id = id;
          state.productsList.push(product);
          setSection({
            action: "Продукты",
            category: category,
            subcategory: subcategory,
          });
        });
      });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AddProductStyles>
      <div className="add-product">
        <div className="title">
          <h1>Добавление продукта</h1>
        </div>
        <form>
          <TextField
            id="name"
            label="Имя товара"
            variant="outlined"
            onChange={(e) => handleChange({ name: e.target.value })}
          />
          <TextField
            id="purpose"
            label="Назначение"
            multiline
            rows={5}
            variant="outlined"
            onChange={(e) => handleChange({ purpose: e.target.value })}
          />
          {getCharacters()}
          <TextField
            id="conditions"
            label="Условия эксплуатации"
            multiline
            rows={5}
            variant="outlined"
            onChange={(e) => handleChange({ conditions: e.target.value })}
          />
          <TextField
            id="download"
            label="Ссылка на каталог"
            variant="outlined"
            onChange={(e) => handleChange({ download: e.target.value })}
          />
          {getProjects()}
          <TextField
            id="creator"
            label="Имя производителя"
            variant="outlined"
            onChange={(e) => handleChange({ creator: e.target.value })}
          />
          {getManufacturer()}
          {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}
          <Button variant="contained" component="label">
            {imageUpload === null ? "Загрузить фото" : "Обновить фото"}
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
          </Button>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                product.name == null || imageUpload === null
                  ? "outlined"
                  : "contained"
              }
            >
              Добавить продукт
            </Button>
            <Button
              onClick={() =>
                setSection({
                  action: "Продукты",
                  category: category,
                  subcategory: subcategory,
                })
              }
              variant="contained"
            >
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </AddProductStyles>
  );
}
