import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import buttonArrow from "./../../assets/svg/arrowBlack.svg";
import translitRusEng from "translit-rus-eng";

import { md1, md2, md3, md4 } from "./../../assets/media-points";

const ProductsListStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: ${md3}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${md4}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

  .equipment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 200px;
    padding: 20px;
    justify-content: space-between;

    @media (max-width: ${md3}) {
      padding: 15px;
    }
    @media (max-width: ${md4}) {
      padding: 10px;
    }

    &_img {
      width: 100%;
      height: 200px;
      background: none;

      @media (max-width: ${md2}) {
        height: 150px;
      }
      @media (max-width: ${md3}) {
        height: 180px;
      }
      @media (max-width: ${md4}) {
        height: 200px;
      }

      .image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    &_title {
      width: 100%;
      display: flex;
      justify-content: center;

      h2 {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        line-height: 120%;
        font-style: normal;

        @media (max-width: ${md1}) {
          font-size: 17px;
        }
        @media (max-width: ${md2}) {
          font-size: 16px;
        }
        @media (max-width: ${md3}) {
          font-size: 15px;
        }
        @media (max-width: ${md4}) {
          font-size: 14px;
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      width: fit-content;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      padding: 12px 18px;
      background-color: #ffc729;
      font-size: 14px;
      gap: 10px;
      color: black;

      @media (max-width: ${md1}) {
        font-size: 13px;
        padding: 11px 17px;
      }
      @media (max-width: ${md2}) {
        font-size: 12px;
        padding: 10px 16px;
      }
      @media (max-width: ${md4}) {
        font-size: 11px;
        padding: 9px 15px;
      }

      .icon {
        height: 100%;
        width: auto;
      }
    }
  }

  .empty-list {
    font-size: 20px;
  }
`;

export default function ProductsList({ products }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function ShowName(name, link) {
    if (link === undefined) {
      name = name.replaceAll("/", "_").replaceAll(" ", "_");
      console.log(
        translitRusEng(name, {
          slug: true,
        })
      );
    }
  }

  return (
    <ProductsListStyles>
      {products.length > 0 ? (
        products.map((product, index) => (
          <div className="equipment" key={index}>
            <button className="equipment_img">
              <NavLink
                className="image"
                to={`/product/category/subcategory/${product.link}`}
                onClick={ShowName(product.name, product.link)}
              >
                <img src={product.img} alt="" />
              </NavLink>
            </button>
            <div className="equipment_title">
              <h2>{product.name}</h2>
            </div>
            <NavLink
              className="button"
              to={`/product/category/subcategory/${product.link}`}
              onClick={ShowName(product.name, product.link)}
            >
              Подробнее
              <img className="icon" src={buttonArrow} alt="" />
            </NavLink>
          </div>
        ))
      ) : (
        <p className="empty-list">Товаров не найдено</p>
      )}
    </ProductsListStyles>
  );
}