import React from "react";
import {
  collectionGroup,
  getCountFromServer,
  limit,
  startAt,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";
import { getStorage, ref, deleteObject } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { FBFileManager } from "./FileManager";

const firebaseConfig = {
  apiKey: "AIzaSyAUFKAA2MEBwQmdhSMZAdEoIbMMVOQOvoQ",
  authDomain: "arcticconreact.firebaseapp.com",
  projectId: "arcticconreact",
  storageBucket: "arcticconreact.appspot.com",
  messagingSenderId: "536305878922",
  appId: "1:536305878922:web:5b5d92578ca0b6e693ea73",
};

const fileManager = new FBFileManager();

initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();
export const auth = getAuth();

// Products

export async function updateProduct(product) {
  await setDoc(doc(db, "products", product.id), product).catch((error) => {
    console.error("Error writing document: ", error);
  });
}

export async function getProductsByCategoryAndSubcategory(
  category,
  subcategory
) {
  let products = [];

  const productsQuery = query(
    collection(db, "products"),
    where("category", "==", category),
    where("subcategory", "==", subcategory)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return products;
}

export async function getProductByLink(link) {
  const product = [];

  const productsQuery = query(
    collection(db, "products"),
    where("link", "==", link)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        product.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return product[0];
}

export async function getProductByName(name) {
  const product = [];

  const productsQuery = query(
    collection(db, "products"),
    where("name", "==", name)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        product.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return product[0];
}

export async function getProductBySubstring(substring) {
  let products = [];
  let lowerSubstring = substring.toLowerCase();
  let upperSubstring = substring[0].toUpperCase() + substring.substring(1);

  let productsQuery = query(
    collection(db, "products"),
    where("name", ">=", lowerSubstring),
    where("name", "<", lowerSubstring)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  productsQuery = query(
    collection(db, "products"),
    where("name", ">=", upperSubstring),
    where("name", "<", upperSubstring)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return products;
}

export async function getProducts(subcategory) {
  let products = [];

  const productsQuery = query(
    collection(db, "products"),
    where("subcategory", "==", subcategory)
  );

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return products;
}

export async function getAllProducts() {
  let products = [];

  const productsQuery = query(collection(db, "products"));

  await getDocs(productsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return products;
}

export async function addProduct(product) {
  let docRef = await addDoc(collection(db, "products"), product);
  return docRef.id;
}

export async function deleteProduct(product) {
  const docRef = doc(db, "products", product.id);

  deleteDoc(docRef)
    .then(() => {
      fileManager.deleteFile(product.img);
    })
    .catch((error) => {
      console.log(error);
    });
}

// Categories

export async function getCategoryByName(name) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "categories"),
    where("name", "==", name)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories[0];
}

export async function getCategoryByLink(link) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "categories"),
    where("link", "==", link)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories[0];
}

export async function getCategoriesWithSkip(skip) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "categories"),
    orderBy("collectionID"),
    startAt(skip),
    limit(5)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories;
}

export async function getAllCategories() {
  let categories = [];

  const categoriesQuery = query(collection(db, "categories"));

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories;
}

// export async function addCategory(category) {
//   let docRef = await addDoc(collection(db, "categories"), category);
//   return docRef.id;
// }

// export async function deleteCategory(category) {
//   const docRef = doc(db, "categories", category.id);

//   deleteDoc(docRef)
//     .then(() => {
//       deleteFile(news.img);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// }

// Subcategories

export async function getSubCategory(subCategoryName) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "subcategories"),
    where("name", "==", subCategoryName)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories[0];
}

export async function getSubCategories(name) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "subcategories"),
    where("category", "==", name)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories;
}

export async function getSubCategoryByLink(categoryName, link) {
  let categories = [];

  const categoriesQuery = query(
    collection(db, "subcategories"),
    where("category", "==", categoryName),
    where("link", "==", link)
  );

  await getDocs(categoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return categories[0];
}

export async function getAllSubcategories() {
  let subcategories = [];

  const subcategoriesQuery = query(collection(db, "subcategories"));

  await getDocs(subcategoriesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        subcategories.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return subcategories;
}

export async function addSubcategory(subcategory) {
  let docRef = await addDoc(collection(db, "subcategories"), subcategory);
  return docRef.id;
}

// Manufacturer

export async function getManufacturers() {
  let manufacturers = {};

  const manufacturersQuery = query(collection(db, "manufacturers"));

  await getDocs(manufacturersQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        manufacturers = doc.data();
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return manufacturers;
}

// Services
export async function getServices() {
  let services = [];

  const manufacturersQuery = query(collection(db, "services"));

  await getDocs(manufacturersQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        services.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return services;
}

export async function getService(link) {
  let services = [];

  const manufacturersQuery = query(
    collection(db, "services"),
    where("link", "==", link)
  );

  await getDocs(manufacturersQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        services.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return services[0];
}

export async function getServicesCount() {
  const coll = collection(db, "services");
  const snap = await getCountFromServer(coll);

  return snap.data().count;
}

export async function addService(service) {
  let docRef = await addDoc(collection(db, "services"), service);
  return docRef.id;
}

export async function updateService(services) {
  await updateDoc(doc(db, "services", services.id), services).catch((error) => {
    console.error("Error writing document: ", error);
  });
}

export async function deleteService(service) {
  const docRef = doc(db, "services", service.id);

  deleteDoc(docRef).catch((error) => {
    console.log(error);
  });
}

// News
export async function getAllNews() {
  let news = [];

  const newsQuery = query(collection(db, "news"), orderBy("postID", "desc"));

  await getDocs(newsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        news.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return news;
}

export async function getPostByID(postID) {
  let news = [];

  const newsQuery = query(
    collection(db, "news"),
    where("postID", "==", parseInt(postID))
  );

  await getDocs(newsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        news.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return news[0];
}

export async function getNews(skip) {
  let news = [];

  const newsQuery = query(
    collection(db, "news"),
    orderBy("id", "desc"),
    startAt(skip),
    limit(3)
  );

  await getDocs(newsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        news.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return news;
}

export async function addNews(news) {
  let docRef = await addDoc(collection(db, "news"), news);
  return docRef.id;
}

export async function updateNews(news) {
  await updateDoc(doc(db, "news", news.id), news).catch((error) => {
    console.error("Error writing document: ", error);
  });
}

export async function deleteNews(news) {
  const docRef = doc(db, "news", news.id);

  deleteDoc(docRef)
    .then(() => {
      fileManager.deleteFile(news.img);
    })
    .catch((error) => {
      console.log(error);
    });
}

// work with Customers collection
export async function addCustomer(customer) {
  return await addDocumentToCollection(customer, "customers");
}

export async function deleteCustomer(customer) {
  return await deleteDocumentFromCollection(customer, "customers");
}

export async function getCustomers() {
  return await downloadCollection("customers");
}

// work with Partners collection
export async function addPartner(partner) {
  return await addDocumentToCollection(partner, "partners");
}

export async function getPartners() {
  return await downloadCollection("partners");
}

export async function deletePartner(partner) {
  return await deleteDocumentFromCollection(partner, "partners");
}

// Work with collection in FB
async function addDocumentToCollection(obj, collectionName) {
  let docRef = await addDoc(collection(db, collectionName), obj);
  return docRef.id;
}

async function deleteDocumentFromCollection(obj, collectionName) {
  const docRef = doc(db, collectionName, obj.id);

  deleteDoc(docRef).catch((error) => {
    console.log(error);
  });
}

async function downloadCollection(collectionName) {
  let list = [];

  const newsQuery = query(collection(db, collectionName));

  await getDocs(newsQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return list;
}
