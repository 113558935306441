import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/Firebase";

// nikita@gmail.com
// 123456

import styled from "styled-components";

const LoginFormStyles = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    border-radius: 0.45rem;
    border: 1px solid gray;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      flex-wrap: wrap;

      label {
        font-weight: bold;
      }
      input {
        padding: 10px;
        background: rgb(232, 240, 254);
        border-radius: 0.4rem;
        border: 1px solid rgb(232, 240, 254);

        :focus {
          border: 1px solid #1BC5BD;
        }
      }
    }
    button {
      cursor: pointer;
      margin-left: auto;
      width: fit-content;
      padding: 10px 20px;
      background-color: #ffd600;
      border-radius: 0.5rem;
      transition: all 0.1s ease;

      :hover {
        color: white;
        background-color: black;
      }
    }
  }
`;

export default function LoginForm(props) {
  let email = sessionStorage.getItem("login");
  let password = sessionStorage.getItem("password");

  if ((email != null) & (password != null)) {
    signInWithEmailAndPassword(auth, email, password).then((cred) => {
      if (cred != null) {
        props.setAutorised(true);
      }
    });
  }

  function singUp(e) {
    e.preventDefault();

    const { email, password } = e.target.elements;

    signInWithEmailAndPassword(auth, email.value, password.value)
      .then((cred) => {
        if (cred == null) {
          alert("wrong user data");
        } else {
          sessionStorage.setItem("login", email.value);
          sessionStorage.setItem("password", password.value);

          props.setAutorised(true);
        }
        e.target.reset();
      })
      .catch((err) => {
        alert("wrong user data");
        console.log(err.message);
      });
  }

  return (
    <LoginFormStyles>
      <form className="signup" onSubmit={singUp}>
        <div className="email">
          <label htmlFor="email">Email:</label>
          <input type="email" name="email" />
        </div>
        <div className="password">
          <label htmlFor="password">Password:</label>
          <input type="password" name="password" />
        </div>
        <button>Войти</button>
      </form>
    </LoginFormStyles>
  );
}
