import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import modalBackImg from "./../../../assets/img/MainPage/ModalThree/back.svg";
import modalFrontImg from "./../../../assets/img/MainPage/ModalThree/front.png";

const ModalThreeStyles = styled.div`
  .modal__three {
    display: none;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(-2.88%, #000000),
        color-stop(50%, rgba(0, 0, 0, 0))
      ),
      #1d1d1d;
    background: linear-gradient(0deg, #000000 -2.88%, rgba(0, 0, 0, 0) 50%),
      #1d1d1d;
  }

  .modal__three .modal_close-button {
    position: absolute;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-bottom: 40px;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    z-index: 101;
    cursor: pointer;
  }

  .modal__three .modal_close-button:hover {
    opacity: 1;
  }

  .modal__three .modal_close-button:before,
  .modal__three .modal_close-button:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #ffc729;
  }

  .modal__three .modal_close-button:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .modal__three .modal_close-button:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .modal__three .block {
    height: 100%;
    width: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767.98px) {
    .modal__three .block {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 0px;
    }
  }
  .modal__three .block__image {
    height: 100%;
    width: 100%;
    position: relative;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3/4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @media (max-width: 767.98px) {
    .modal__three .block__image {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1/2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      grid-row: 1/2;
      height: 500px;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__image {
      height: 300px;
    }
  }

  .modal__three .block__image img {
    width: 100%;
  }

  .modal__three .block__image .modal__one_front {
    position: absolute;
    width: 140%;
    margin-left: 20px;
    z-index: 3;
  }

  @media (max-width: 767.98px) {
    .modal__three .block__image .modal__one_front {
      top: 0;
      width: auto;
      height: 500px;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__image .modal__one_front {
      height: 300px;
    }
  }

  .modal__three .block__image .back {
    position: absolute;
    width: 150%;
    height: 600px;
    z-index: 2;
  }

  @media (max-width: 767.98px) {
    .modal__three .block__image .back {
      top: 0px;
      left: auto;
      height: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__image .back {
      width: auto;
    }
  }

  .modal__three .block__info {
    height: 100%;
    width: 100%;
    position: relative;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    color: white;
    font-family: "Montserrat", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 50px;
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1/2;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-row: 2/3;
      padding-bottom: 150px;
    }
  }

  .modal__three .block__info_text {
    padding-bottom: 50px;
  }

  @media (max-width: 1199.98px) {
    .modal__three .block__info_text {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 991.98px) {
    .modal__three .block__info_text {
      padding-bottom: 30px;
    }
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info_text {
      padding-bottom: 25px;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__info_text {
      padding-bottom: 20px;
    }
  }

  .modal__three .block__info_text p {
    padding: 20px 0px;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
  }

  @media (max-width: 1199.98px) {
    .modal__three .block__info_text p {
      font-size: 15px;
      padding: 18px 0px;
    }
  }

  @media (max-width: 991.98px) {
    .modal__three .block__info_text p {
      font-size: 14px;
      padding: 16px 0px;
    }
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info_text p {
      font-size: 13px;
      padding: 14px 0px;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__info_text p {
      font-size: 12px;
      padding: 10px 0px;
    }
  }

  .modal__three .block__info_text ul {
    width: 100%;
    list-style-position: inside;
  }

  .modal__three .block__info_text ul li {
    list-style: disc inside;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
  }

  @media (max-width: 1199.98px) {
    .modal__three .block__info_text ul li {
      font-size: 15px;
    }
  }

  @media (max-width: 991.98px) {
    .modal__three .block__info_text ul li {
      font-size: 14px;
    }
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info_text ul li {
      font-size: 13px;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__info_text ul li {
      font-size: 12px;
    }
  }

  .modal__three .block__info .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-column-gap: 10px;
    column-gap: 10px;
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info .buttons {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 200px;
      row-gap: 20px;
    }
  }

  .modal__three .block__info .buttons a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    border: 1px solid white;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 0px 5px;
    height: 50px;
  }

  .modal__three .block__info .buttons a:hover {
    color: black;
    border: 1px solid #ffc729;
    background: #ffc729;
  }

  @media (max-width: 1199.98px) {
    .modal__three .block__info .buttons a {
      font-size: 16px;
      height: 40px;
    }
  }

  @media (max-width: 991.98px) {
    .modal__three .block__info .buttons a {
      font-size: 14px;
      height: 30px;
    }
  }

  @media (max-width: 767.98px) {
    .modal__three .block__info .buttons a {
      font-size: 13px;
      height: 25px;
    }
  }

  @media (max-width: 479.98px) {
    .modal__three .block__info .buttons a {
      font-size: 12px;
    }
  }
`;

export default function ModalThree() {
  return (
    <ModalThreeStyles>
      <section className="modal__three">
        <div className="modal_close-button"></div>
        <div className="container">
          <div className="block">
            <div className="block__image">
              <img
                className="modal__one_front"
                src={modalFrontImg}
                alt="front image"
              />
              <img className="back" src={modalBackImg} alt="svg" />
            </div>
            <div className="block__info">
              <div className="block__info_text">
                <p>
                  Существенную часть портфеля заказов ООО « Арктик Энергострой»
                  формируют крупнейшие компании топливно-энергетического
                  комплекса России, такие как: ПАО «СИБУР Холдинг»,
                  Госкорпорация «Росатом», ООО «Славнефть-Красноярскнефтегаз»,
                  ПАО «ЯТЭК», ООО «ЗАРУБЕЖНЕФТЬ-добыча Харьяга», ПАО
                  «Криогенмаш», АО «РОСАН ИНТЕРНЕШНЛ», АО «Ангарская
                  нефтехимическая компания».
                </p>
                <p>
                  За время деятельности нами запроектировано, поставлено,
                  смонтировано и введено в эксплуатацию в различных регионах
                  страны, в том числе в районах крайнего севера с суровыми
                  климатическими условиями, такое оборудование, как:
                </p>
                <ul>
                  <li>емкостное и теплообменное оборудование</li>
                  <li>запорно-регулирующая арматура</li>
                  <li>контрольно-пропускные пункты</li>
                  <li>терминалы мобильной связи</li>
                  <li>сетевое и коммутационное оборудование</li>
                  <li>взрывозащищенное оборудование</li>
                  <li>газоанализаторы</li>
                  <li>контрольно-измерительные приборы и автоматика</li>
                  <li>кабельная арматуры и муфты</li>
                  <li>кабельнесущие системы</li>
                  <li>кабельно-проводниковая продукция</li>
                  <li>стальные опоры</li>
                  <li>электрощитовое оборудование</li>
                  <li>источники бесперебойного питания</li>
                  <li>вентиляционное оборудование</li>
                  <li>частотные преобразователи</li>
                  <li>трансформаторы и трансформаторные подстанции</li>
                  <li>котельное оборудование</li>
                  <li>оборудование для крепления скважин и прочее</li>
                </ul>
                <p>
                  С более подробной информацией об опыте Компании, оборудовании
                  предоставляемых услугах Вы сможете ознакомится в разделах
                  сайта:
                </p>
              </div>
              <div className="buttons">
                <NavLink className="button" to="/equipments">
                  ПРОДУКЦИЯ
                </NavLink>
                <NavLink className="button" to="/services">
                  УСЛУГИ И СЕРВИС
                </NavLink>
                <NavLink className="button" to="/projects">
                  Проекты
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ModalThreeStyles>
  );
}
