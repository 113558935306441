import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import {
  getCertificates,
  addCertificateItem,
} from "../../firebase/Certificates";
import { FBFileManager } from "../../firebase/FileManager";

const СertificatesStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;
    padding: 40px 0px;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  .block {
    padding: 30px 0px;

    .certificates {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      .certificate {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: rgb(252, 252, 252);
        border-radius: 0.45rem;
        border: 1px solid lightgray;
        transition: all 0.3s ease;

        @media (min-width: 800px) {
          :hover {
            transform: scale(1.1);
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }
`;

export default function Сertificates() {
  const [chapters, setChapters] = useState([1]);
  const [сertificateList, setCertificates] = useState([]);

  useEffect(() => {
    getCertificates().then((data) => {
      setCertificates(data);
    });
    window.scroll(0, 0);
  }, []);

  return (
    <СertificatesStyles>
      <div className="container">
        <h1>Сертификаты</h1>
        <div className="block">
          <div className="certificates">
            {сertificateList.map((certificate, certificateIndex) => (
              <div className="certificate" key={certificateIndex}>
                <img src={certificate.url} />
              </div>
            ))}
          </div>
          {/* {chapters.map((chapter, index) => (
            <div className="chapter" key={index}>
              <h2>Chapter {chapter}</h2>

            </div>
          ))} */}
        </div>
      </div>
    </СertificatesStyles>
  );
}
