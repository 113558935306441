import { db } from "./FireBaseManager";
import { FBFileManager } from "./FileManager";
import { collection, getDocs, query, addDoc } from "firebase/firestore";

export async function getCertificates() {
  let certificates = [];

  const certificatesQuery = query(collection(db, "certificateItem"));

  await getDocs(certificatesQuery)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        certificates.push({ id: doc.id, ...doc.data() });
      });
    })
    .catch((err) => {
      console.log(err.message);
    });

  return certificates;
}

export async function getCertificatesStorage() {
  var fileManages = new FBFileManager();

  return fileManages.getFiles("images/certificates");
}

export async function addCertificateItem(certificateItem) {
    let docRef = await addDoc(collection(db, "certificateItem"), certificateItem);
    return docRef.id;
}