import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { updateService } from "../../../../firebase/Firebase";
import translitRusEng from "translit-rus-eng";

const EditServiceStyles = styled.div`
  .edit-service {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;

      .data {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .controller-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function EditService({ state, _service, setSection }) {
  const [service, setService] = useState();

  const handleChange = (value) => {
    setService((service) => ({
      ...service,
      ...value,
    }));
  };

  function uploadFile() {
    var newLink = service.name.replaceAll("/", "_").replaceAll(" ", "_");
    var link = translitRusEng(newLink, {
      slug: true,
    });
    service.link = link;
    updateService(service).then(() => {
      var serviceIndex = state.services.findIndex((x) => x.id === service.id);
      state.services[serviceIndex] = service;
      setSection({ action: "Сервисы" });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (service == null) {
      setService(_service);
    }
  }, [service]);

  return (
    <EditServiceStyles>
      {service != null ? (
        <div className="edit-service">
          <div className="title">
            <h1>Редактирование услуги / сервиса</h1>
          </div>
          <form>
            <TextField
              id="name"
              label="Название услуги"
              variant="outlined"
              defaultValue={service.name}
              onChange={(e) => handleChange({ name: e.target.value })}
            />
            <TextField
              id="title"
              label="Заголовок"
              variant="outlined"
              defaultValue={service.title}
              onChange={(e) => handleChange({ title: e.target.value })}
            />
            <div className="data">
              <h2>Описание услуги / сервиса:</h2>
              {Array.isArray(service.data) ? (
                service.data.map((paragraph, index) => {
                  return (
                    <TextField
                      key={index}
                      label="Параграф"
                      variant="outlined"
                      defaultValue={paragraph}
                      multiline
                      rows={3}
                      onChange={(e) => {
                        service.data[index] = e.target.value;
                        let newList = [...service.data];
                        handleChange({ data: newList });
                      }}
                    />
                  );
                })
              ) : (
                <TextField
                  label="Параграф"
                  variant="outlined"
                  defaultValue={service.data}
                  multiline
                  rows={3}
                  onChange={(e) => {
                    handleChange({ data: e.target.value });
                  }}
                />
              )}
              <div className="controller-buttons">
                <Button
                  sx={{ maxWidth: "30px" }}
                  onClick={() => {
                    let list = Array.isArray(service.data)
                      ? [...service.data]
                      : [service.data];
                    if (service.data.length == 0) return;
                    list.pop();
                    handleChange({ data: list });
                  }}
                  variant="contained"
                >
                  -
                </Button>
                <Button
                  sx={{ maxWidth: "30px" }}
                  onClick={() => {
                    let list = Array.isArray(service.data)
                      ? [...service.data]
                      : [service.data];
                    list.push("");
                    handleChange({ data: list });
                  }}
                  variant="contained"
                >
                  +
                </Button>
              </div>
            </div>
            <div className="action-buttons">
              <Button onClick={uploadFile} variant={"contained"}>
                Редактировать услугу
              </Button>
              <Button
                onClick={() => setSection({ action: "Сервисы" })}
                variant="contained"
              >
                Отменить
              </Button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </EditServiceStyles>
  );
}
