import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { deleteNews, getAllNews } from "../../../../firebase/Firebase";
import News from "./News";

const NewsListStyles = styled.div`
  .news {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
      .add-news {
        .add-main-category-button {
          z-index: 10;
          h2 {
            padding-top: 2px;
            padding-left: 5px;
          }
        }
      }
    }
    .news-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);

      .add-news {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    }
  }
`;

export default function NewsList({ state, setSection }) {
  const [news, setNews] = useState(null);

  function addNews() {
    let lastPostID = news.reduce(
      (acc, shot) => (acc = acc > shot.postID ? acc : shot.postID),
      0
    );
    setSection({ action: "Добавление новости", postID: lastPostID });
  }

  function editNews(_news) {
    setSection({ action: "Редактирование новости", news: _news });
  }

  function removeNews(news) {
    let deleteMessage = `Вы уверенны что хотите удалить новость ${news.title}?`;
    let isDelete = window.confirm(deleteMessage);
    if (isDelete) {
      deleteNews(news).then(() => {
        let saveNews = state.news.filter((x) => x.title != news.title);
        state.news = saveNews;
        setNews(state.news);
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (news == null) {
      if (state.news === undefined) {
        getAllNews().then((data) => {
          state.news = data;
          setNews(state.news);
        });
      } else {
        setNews(state.news);
      }
    }
  }, [news]);

  return (
    <NewsListStyles>
      <div className="news">
        <div className="title">
          <h1>Все новости</h1>
        </div>
        <div className="news-list">
          <div className="add-news">
            <Fab className="add-button" color="primary" onClick={addNews}>
              <AddIcon />
            </Fab>
          </div>
          {news !== null
            ? news.map((_news, index) => (
              <News
                key={index}
                news={_news}
                edit={editNews}
                remove={removeNews}
              />
            ))
            : ""}
        </div>
      </div>
    </NewsListStyles>
  );
}
