import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import arrowBlack from "./../../assets/svg/home/arrow_black.svg";

import { getNews } from "./setNews";

const NewsStyled = styled.div`
  .two {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #323232;

    .container {
      position: relative;
      margin: 0px 50px;
      max-width: 1200px;
      height: 100%;
      width: 100%;
    }

    @media (max-width: 767.98px) {
      .container {
        margin: 0px 30px;
      }
    }

    @media (max-width: 479.98px) {
      .container {
        margin: 0px 20px;
      }
    }

    .head__block {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 50px 0px;

      h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 40px;
        color: white;

        @media (max-width: 1199.98px) {
          font-size: 35px;
        }
        @media (max-width: 991.98px) {
          font-size: 30px;
        }
        @media (max-width: 767.98px) {
          font-size: 25px;
        }
        @media (max-width: 479.98px) {
          font-size: 20px;
        }
      }
    }

    .block {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }

    .list {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      display: flex;

      &__white {
        position: relative;
        height: 100%;
        width: 60%;
        background-color: white;

        &_orange {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 60px;
          background-color: #ffee49;
        }
      }

      &__block {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 3;

        .container {
          height: 60px;
          display: flex;
          align-items: center;

          h2 {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 25px;

            @media (max-width: 1199.98px) {
              font-size: 22px;
            }
            @media (max-width: 991.98px) {
              font-size: 18px;
            }
            @media (max-width: 767.98px) {
              font-size: 15px;
            }
            @media (max-width: 479.98px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__block {
      position: relative;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 50px;
      gap: 20px;

      &_column {
        position: relative;
        z-index: 2;

        .column {
          position: relative;
          display: flex;
          align-items: center;
          gap: 30px;

          @media (max-width: 767.98px) {
            height: auto;
            flex-direction: column;
          }

          &__title {
            width: 40%;
            display: flex;
            align-items: center;
            font-family: "Inter", sans-serif;
            padding: 20px 0px 20px 0px;
            background-color: white;

            @media (max-width: 767.98px) {
              width: 100%;
            }

            .info {
              height: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;

              h2 {
                font-weight: 700;
                color: #3a3c41;
                margin-bottom: 20px;
                font-size: 24px;

                @media (max-width: 1199.98px) {
                  font-size: 22px;
                }
                @media (max-width: 991.98px) {
                  font-size: 18px;
                }
                @media (max-width: 767.98px) {
                  font-size: 16px;
                  margin-bottom: 18px;
                }
                @media (max-width: 479.98px) {
                  font-size: 14px;
                  margin-bottom: 15px;
                }
              }

              h3 {
                font-weight: 400;
                color: #3a3c41;
                font-size: 16px;
                line-height: 187%;

                @media (max-width: 1199.98px) {
                  font-size: 14px;
                }
                @media (max-width: 991.98px) {
                  font-size: 12px;
                }
                @media (max-width: 767.98px) {
                  font-size: 11px;
                }
                @media (max-width: 479.98px) {
                  font-size: 10px;
                }
              }
            }

            a {
              display: none;

              @media (max-width: 767.98px) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 120px;
                margin: 0px 10px;
                z-index: 2;
                background-color: white;
              }
              @media (max-width: 479.98px) {
                width: 100px;
              }
            }
          }

          &__image {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;

            @media (max-width: 767.98px) {
              display: none;
            }

            a {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
              z-index: 2;
              border-radius: 0.43rem;
              transition: all 0.2s ease-in-out;

              :hover {
                padding-left: 6px;
              }

              @media (max-width: 767.98px) {
                display: none;
              }
            }

            img {
              z-index: 1;

              @media (max-width: 767.98px) {
                position: absolute;
                width: 100%;
                bottom: 0px;
              }
              @media (max-width: 479.98px) {
                position: relative;
                top: 0px;
              }
            }

            .post-img {
              left: 0px;
              height: 100%;
              width: auto;
            }
          }
          &__image.empty {
            @media (max-width: 767.98px) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default function News({ state }) {
  const [news, setNews] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (news == null) {
      if (state.news == null) {
        getNews().then((data) => {
          state.news = data;
          setNews(data);
        });
      } else {
        setNews(state.news);
      }
    }
  }, []);

  return (
    <NewsStyled>
      <section className="two">
        <div className="head__block">
          <div className="container">
            <h1>ПРЕСС-ЦЕНТР</h1>
          </div>
        </div>
        <div className="block">
          <div className="list">
            <div className="list__white">
              <div className="list__white_orange"></div>
            </div>
            <div className="list__block">
              <div className="container">
                <h2>НОВОСТИ КОМПАНИИ</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="two__block">
              {news != null
                ? news.map((post, index) => (
                    <div className="two__block_column" key={index}>
                      <div className="column">
                        <div className="column__title">
                          <div className="info">
                            <h2>{post.title}</h2>
                            <h3>{post.short}</h3>
                          </div>
                          <NavLink to={`/news/${post.postID}`}>
                            <img src={arrowBlack} alt="arrow" />
                          </NavLink>
                        </div>
                        <div className="column__image">
                          <NavLink to={`/news/${post.postID}`}>
                            <img src={arrowBlack} alt="arrow" />
                          </NavLink>
                        </div>
                        {/* <div
                          className={
                            post.imgShort != undefined
                              ? "column__image"
                              : "column__image empty"
                          }
                        >
                          <NavLink to={`/news/${post.postID}`}>
                            <img src={arrowBlack} alt="arrow" />
                          </NavLink>
                          {post.img != undefined ? (
                            <img
                              className="post-img"
                              src={post.imgShort}
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </div> */}
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </section>
    </NewsStyled>
  );
}
