import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import button from "./../../assets/svg/home/details_black.svg";
import styled from "styled-components";

const StyledCustomersCarousel = styled.div`
  width: 100%;

  .slick-slider {
    padding-top: 0;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    .button {
      height: fit-content;
      width: fit-content;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: all 0.2s ease;

      :hover {
          transform: scale(1.03);
      }

      img {
        position: relative;
        width: 100%;
        height: 40px;
        background-color: none;
      }
    }
  }
  .rotate {
    transform: rotate(180deg);
  }
  .item {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 767.98px) {
      height: 100px;
    }
    @media (max-width: 479.98px) {
      height: 80px;
    }

    a {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-height: 100px;
      padding: 5px;
      max-width: 100%;
      margin: 0 auto;

      @media (max-width: 767.98px) {
        height: auto;
        max-height: 70px;
        width: auto;
        max-width: 100px;
      }
      @media (max-width: 479.98px) {
        height: auto;
        max-height: 60px;
      }
    }
  }
  .button_next {
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
  }

  .slick-arrow,
  .slick-prev::before {
    display: none;
    pointer-events: none;
  }
`;

export default function CustomersCarousel({ items, params }) {
  const sliderRef = useRef(null);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: params.show,
    slidesToScroll: params.scroll,
    rows: params.rows,
    slidesPerRow: 1,
  };

  function getSliderItems(images) {
    let sliderItems = [];
    let len = images.length;
    for (let i = 0; i < len; i++) {
      sliderItems.push(
        <div className="item" key={i}>
          {images[i].url !== undefined && images[i].url !== '' ? (
            <a href={images[i].url} target="_blank">
              <img src={images[i].img} />
            </a>
          ) : (
            <a>
              <img src={images[i].img} />
            </a>
          )}
        </div>
      );
    }

    return sliderItems;
  }

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <StyledCustomersCarousel>
      <Slider ref={sliderRef} {...settings}>
        {getSliderItems(items)}
      </Slider>
      <div className="buttons">
        <button className="button" onClick={previous}>
          <img className="rotate" src={button} alt="" />
          Назад
        </button>
        <button className="button" onClick={next}>
          Далее
          <img src={button} alt="" />
        </button>
      </div>
    </StyledCustomersCarousel>
  );
}
