import React, { useState } from "react";
import AddService from "./AddService";
import EditService from "./EditService";
import Services from "./Services";

export default function ServicesController({ state }) {
  const [section, setSection] = useState({
    action: "Сервисы",
    service: null,
  });

  function getSection() {
    switch (section.action) {
      case "Сервисы":
        return <Services state={state} setSection={setSection} />;
      case "Добавление сервиса":
        return <AddService state={state} setSection={setSection} />;
      case "Редактирование сервиса":
        return (
          <EditService
            state={state}
            _service={section.service}
            setSection={setSection}
          />
        );
    }
  }

  return getSection();
}
