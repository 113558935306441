import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useParams } from "react-router-dom";
import { Markup } from "interweave";
import arrow from "./../../assets/svg/services/arrow.svg";

import { md1, md2, md3, md4 } from "./../../assets/media-points";

import { getPostByID } from "../../firebase/Firebase";

const NewsPostStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }
    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
    .navigation {
      width: 100%;
      display: flex;
      gap: 50px;
      margin-bottom: 40px;

      @media (max-width: ${md3}) {
        flex-direction: column;
        gap: 30px;
      }
      @media (max-width: ${md4}) {
        display: none;
      }

      .path {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-family: "Montserrat", sans-serif;

        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          li {
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 15px;
              font-size: 16px;

              @media (max-width: ${md2}) {
                gap: 10px;
              }

              :visited,
              :active {
                color: black;
              }

              img {
                height: 40%;
                width: 25px;
              }
            }
            .active {
              background: none;
              font-weight: 600;
            }
          }
        }
        .back {
          width: fit-content;
          &_button {
            font-size: 16px;
            background: none;
            display: flex;
            color: black;
            align-items: center;
            gap: 15px;

            .icon {
              transform: rotate(180deg);
              height: 40%;
              width: 25px;
            }
          }
        }
      }
    }
    .block {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 100px;

      .title {
        width: 100%;
        font-family: "Montserrat", sans-serif;
        font-size: 26px;
        padding: 20px 0px 10px 0px;

        @media (max-width: 767.98px) {
          font-size: 18px;
        }
        @media (max-width: 479.98px) {
          font-size: 16px;
        }
      }
      .block-info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .image {
          position: relative;
          width: 100%;
          max-width: 600px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 300px;
          overflow: hidden;

          :hover {
            overflow: visible;
            img {
              transform: scale(1.2);
            }
          }

          img {
            position: absolute;
            height: 100%;
          }
        }

        .full {
          display: flex;
          flex-direction: column;
          gap: 14px;
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          line-height: 130%;

          @media (max-width: 767.98px) {
            font-size: 14px;
          }
          @media (max-width: 479.98px) {
            font-size: 12px;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            li {
              list-style-type: disc;
              list-style-position: inside;
            }
          }
        }
      }
    }
  }
`;

export default function NewsPost({ state }) {
  const [post, setPost] = useState(null);

  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (post == null) {
      if (state.news == null) {
        getPostByID(id).then((data) => {
          setPost(data);
        });
        return;
      }
      setPost(state.news.find((x) => x.postID == id));
    }
  }, []);

  function showNavigation() {
    return (
      <div className="navigation">
        <div className="path">
          <ul>
            <li>
              <NavLink className="button" to="/">
                Главная <img className="icon" src={arrow} alt="" />
              </NavLink>
            </li>
            <li>
              <Link className="button" to="/news">
                Новости <img className="icon" src={arrow} alt="" />
              </Link>
            </li>
            {post != null ? (
              <li>
                <button className="button active">{post.title}</button>
              </li>
            ) : (
              ""
            )}
          </ul>
          <div className="back">
            <NavLink className="back_button" to={`/news`}>
              <img className="icon" src={arrow} alt="" />
              Назад
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  function showImage(img) {
    if (img != undefined && img !== "") {
      return (
        <div className="image">
          <img src={img} alt="" />
        </div>
      );
    }

    return "";
  }

  function getTemplate(postType) {
    let classResult = "block-info";

    switch (postType) {
      case 2: {
        classResult += " block-info-certificate";
      }
    }

    return classResult;
  }

  return (
    <NewsPostStyle>
      <div className="container">
        {showNavigation()}

        {post != null ? (
          <div className="block">
            <div className="title">{post.title}</div>
            <div className={getTemplate(post.type)}>
              {/* {showImage(post.img)} */}
              {Array.isArray(post.full) ? (
                <div className="full">
                  {post.full.map((chapter, index) => {
                    return (
                      <p key={index}>
                        <Markup content={chapter} />
                      </p>
                    );
                  })}
                </div>
              ) : (
                <Markup className="full" content={post.full} />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </NewsPostStyle>
  );
}
