import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { deleteService, getServices } from "../../../../firebase/Firebase";
import Service from "./Service";

const ServicesStyles = styled.div`
  .services {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }
    .services-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);

      .add-service {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      }
    }
  }
`;

export default function Services({ state, setSection }) {
    const [services, setServices] = useState(null);

    function addService() {
        setSection({ action: "Добавление сервиса" });
    }

    function editService(service) {
        setSection({ action: "Редактирование сервиса", service: service });
    }

    function removeService(service) {
        let deleteMessage = `Вы уверенны что хотите удалить услугу ${service.name}?`;
        let isDelete = window.confirm(deleteMessage);
        if (isDelete) {
            deleteService(service).then(() => {
                let saveServices = state.services.filter((x) => x.name != service.name);
                state.services = saveServices;
                setServices(state.services);
            })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (services == null) {
            if (state.service === undefined) {
                getServices().then((data) => {
                    state.services = data;
                    setServices(state.services);
                });
            } else {
                setServices(state.services);
            }
        }
    }, [services]);

    return (
        <ServicesStyles>
            <div className="services">
                <div className="title">
                    <h1>Все услуги и сервисы</h1>
                </div>
                <div className="services-list">
                    <div className="add-service">
                        <Fab className="add-button" color="primary" onClick={addService}>
                            <AddIcon />
                        </Fab>
                    </div>
                    {services !== null
                        ? services.map((service, index) => (
                            <Service
                                key={index}
                                service={service}
                                edit={editService}
                                remove={removeService}
                            />
                        ))
                        : ""}
                </div>
            </div>
        </ServicesStyles>
    );
}
