import { initializeApp } from "firebase/app";

import {
  getStorage,
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";

import { v4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyAUFKAA2MEBwQmdhSMZAdEoIbMMVOQOvoQ",
  authDomain: "arcticconreact.firebaseapp.com",
  projectId: "arcticconreact",
  storageBucket: "arcticconreact.appspot.com",
  messagingSenderId: "536305878922",
  appId: "1:536305878922:web:5b5d92578ca0b6e693ea73",
};

initializeApp(firebaseConfig);

const storage = getStorage();

export class FBFileManager {
  async getFiles(path) {
    var urls = [];
    const listRef = ref(storage, path);

    try {
      const res = await listAll(listRef);
      await Promise.all(
        res.items.map(async (item) => {
          var url = await getDownloadURL(item);
          urls.push(url);
        })
      );
    } catch (error) {
      console.error(error);
    }

    return urls;
  }

  async updateImage(remove, upload, catalog) {
    let newUrl = null;

    await this.deleteFile(remove).then(async () => {
      newUrl = await this.uploadImage(upload, catalog);
    });

    return newUrl;
  }

  async uploadImage(file, catalog) {
    const imageRef = ref(storage, `images/${catalog}/${file.name + v4()}`);

    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        return url;
      });
    });
  }

  async deleteFile(link) {
    await deleteObject(ref(storage, link));
  }
}
