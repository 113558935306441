import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { storage } from "../../../../../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import translitRusEng from "translit-rus-eng";

const AddSubcategoryStyles = styled.div`
  .add-subcategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function AddSubcategory({ state, category, setSection }) {
  const [subcategoryName, setSubcategoryName] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);

  function cancel() {
    setSection({ action: "Подкатегории", category: category });
  }

  function uploadFile() {
    if (imageUpload == null || subcategoryName == null) return;
    const imageRef = ref(storage, `images/subcategories/${imageUpload.name + v4()}`);

    var newSubcategory = {
      img: "",
      isSubcategory: false,
      link: "",
      name: "",
      category: category.name
    };

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        newSubcategory.img = url;
        newSubcategory.name = subcategoryName;
        var newLink = subcategoryName.replaceAll("/", "_").replaceAll(" ", "_");
        var link = translitRusEng(newLink, {
          slug: true,
        });
        newSubcategory.link = link;
        state.products.subcategories.push(newSubcategory);
        setSection({ action: "Подкатегории", category: category });
      });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AddSubcategoryStyles>
      <div className="add-subcategory">
        <div className="title">
          <h1>Добавление новой подкатегории товаров</h1>
        </div>
        <form>
          <TextField
            id="name"
            label="Имя подкатегории"
            variant="outlined"
            onChange={(event) => setSubcategoryName(event.target.value)}
          />
          {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}
          <Button variant="contained" component="label">
            {imageUpload === null ? "Загрузить фото" : "Обновить фото"}
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
          </Button>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                imageUpload === null || subcategoryName === null
                  ? "outlined"
                  : "contained"
              }
            >
              Добавить подкатегорию
            </Button>
            <Button onClick={cancel} variant="contained">
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </AddSubcategoryStyles>
  );
}
