import React from "react";
import {
  collectionGroup,
  getCountFromServer,
  limit,
  startAt,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUFKAA2MEBwQmdhSMZAdEoIbMMVOQOvoQ",
  authDomain: "arcticconreact.firebaseapp.com",
  projectId: "arcticconreact",
  storageBucket: "arcticconreact.appspot.com",
  messagingSenderId: "536305878922",
  appId: "1:536305878922:web:5b5d92578ca0b6e693ea73",
};

initializeApp(firebaseConfig);

export const db = getFirestore();
export const auth = getAuth();