import React from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const SubcategoryStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-height: 200px;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

  .image {
    cursor: pointer;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .name {
    width: 100%;
    margin-bottom: 10px;
    h2 {
      width: 100%;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      line-height: 120%;
      font-style: normal;
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 999;
  }
`;

export default function Subcategory({
  category,
  subcategory,
  showProducts,
  remove,
  edit,
}) {
  return (
    <SubcategoryStyles>
      <div
        className="image"
        onClick={() => showProducts(category, subcategory)}
      >
        <img src={subcategory.img} alt="" />
      </div>
      <div className="name">
        <h2>{subcategory.name}</h2>
      </div>
      <div className="buttons">
        {/* <Fab
          className="edit-button"
          color="primary"
          size="small"
          onClick={() => {
            edit(subcategory);
          }}
        >
          <EditIcon />
        </Fab>
        <Fab
          className="delete-button"
          color="secondary"
          size="small"
          onClick={() => {
            remove(subcategory.name);
          }}
        >
          <DeleteIcon />
        </Fab> */}
      </div>
    </SubcategoryStyles>
  );
}
