import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { getSubCategories } from "../../../../../firebase/Firebase";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Subcategory from "./Subcategory";

const SubcategoriesStyles = styled.div`
  nav {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
    .top-nav {
      display: flex;
      gap: 18px;
    }
    ul {
      width: fit-content;
      li {
        display: flex;
        align-items: center;
        font-size: 18px;
        gap: 6px;
        color: gray;
        cursor: pointer;
        :hover {
          color: black;
        }
        svg {
          margin-top: 1px;
          height: 0.9em;
        }
      }
      .disable {
        cursor: auto;
        color: black;
      }
    }
  }

  .subcategories {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
      .add-category {
        .add-main-category-button {
          z-index: 10;
          h2 {
            padding-top: 2px;
            padding-left: 5px;
          }
        }
      }
    }
    .subcategory-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1199.98px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .add-subcategory {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    }
  }
`;

export default function Subcategories({ state, category, setSection }) {
  const [subcategories, setSubcategories] = useState(null);

  function openProducts(_category, _subcategory) {
    setSection({
      action: "Продукты",
      category: _category,
      subcategory: _subcategory,
    });
  }

  function addSubcategory() {
    setSection({ action: "Добавить подкатегорию", category: category });
  }

  function editSubcategory(_subcategory) {
    setSection({
      action: "Редактировать подкатегорию",
      subcategory: _subcategory,
      category: category,
    });
  }

  function removeSubcategory(name) {
    let deleteMessage = `Вы уверенны что хотите удалить подкатегорию ${name}?`;
    let isDelete = window.confirm(deleteMessage);
    if (isDelete) {
      let _subcategories = state.products.subcategories.filter(
        (x) => x.name != name
      );
      console.log(_subcategories);
      state.products.subcategories = _subcategories;
      setSubcategories(state.products.subcategories);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (subcategories == null) {
      if (state.products.subcategories === undefined) {
        state.products.subcategories = {};
        getSubCategories(category.name).then((data) => {
          state.products.subcategories = data;
          setSubcategories(data);
        });
      } else {
        let sub = state.products.subcategories.filter(
          (subcat) => subcat.category == category.name
        );
        if (sub.length == 0) {
          getSubCategories(category.name).then((data) => {
            state.products.subcategories.push(...data);
            setSubcategories(data);
          });
        } else {
          setSubcategories(sub);
        }
      }
    }
  }, [subcategories]);

  return (
    <SubcategoriesStyles>
      <nav>
        <ul className="top-nav">
          <li onClick={() => setSection({ action: "Категории" })}>
            Категории
            <ArrowForwardIcon />
          </li>
          <li className="disable">{category.name}</li>
        </ul>
        <ul>
          <li onClick={() => setSection({ action: "Категории" })}>
            <ArrowBackIcon />
            Назад
          </li>
        </ul>
      </nav>

      <div className="subcategories">
        <div className="title">
          <h1>Подкатегории</h1>
        </div>
        <div className="subcategory-list">
          {/* <div className="add-subcategory">
            <Fab
              className="add-button"
              color="primary"
              onClick={addSubcategory}
            >
              <AddIcon />
            </Fab>
          </div> */}
          {subcategories != null
            ? subcategories.map((subcategory, index) => (
                <Subcategory
                  key={index}
                  category={category}
                  subcategory={subcategory}
                  showProducts={openProducts}
                  remove={removeSubcategory}
                  edit={editSubcategory}
                />
              ))
            : ""}
        </div>
      </div>
    </SubcategoriesStyles>
  );
}
