import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import AboutBlock from "../../components/AboutBlock/AboutBlock";
import CarrierBlock from "../../components/CarrierBlock/CarrierBlock";
import CertificatesBlock from "../../components/CertificatesBlock/CertificatesBlock";
import ChoiceBlock from "../../components/ChoiceBlock/ChoiceBlock";
import EquipmentBlock from "../../components/EquipmentBlock/EquipmentBlock";
import LobbyBlock from "../../components/LobbyBlock/LobbyBlock";
import MapBlock from "../../components/MapBlock/MapBlock";
import NewsBlock from "../../components/NewsBlock/NewsBlock";
import ServicesBlock from "../../components/ServicesBlock/ServicesBlock";
import Preloader from "../../components/Preloader/Preloader";
import SideNav from "../../components/SideNav/SideNav";
import { getAllProducts } from "../../firebase/Firebase";

const HomeStyled = styled.div`
  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  .six {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
  }

  .six .container {
    overflow: hidden;
  }

  .six .next-button {
    display: none;
  }

  .six__block_title {
    padding: 100px 0px;
  }

  @media (max-width: 1199.98px) {
    .six__block_title {
      padding: 80px 0px;
    }
  }

  @media (max-width: 991.98px) {
    .six__block_title {
      padding: 60px 0px;
    }
  }

  @media (max-width: 767.98px) {
    .six__block_title {
      padding: 50px 0px;
    }
  }

  @media (max-width: 479.98px) {
    .six__block_title {
      padding: 40px 0px;
      padding-bottom: 20px;
    }
  }

  .six__block_title h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 96px;
    color: #aaa9ad;
    text-transform: uppercase;
  }

  @media (max-width: 1199.98px) {
    .six__block_title h1 {
      font-size: 80px;
    }
  }

  @media (max-width: 991.98px) {
    .six__block_title h1 {
      font-size: 60px;
    }
  }

  @media (max-width: 767.98px) {
    .six__block_title h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 479.98px) {
    .six__block_title h1 {
      font-size: 30px;
    }
  }

  .six__block_part .part {
    width: 100%;
    padding: 20px 0px;
  }

  .six__block_part .part__title {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .six__block_part .part__title h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    font-size: 35px;
    margin-bottom: 35px;
  }

  @media (max-width: 1199.98px) {
    .six__block_part .part__title h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 991.98px) {
    .six__block_part .part__title h1 {
      font-size: 24px;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 767.98px) {
    .six__block_part .part__title h1 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 479.98px) {
    .six__block_part .part__title h1 {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .six__block_part .part__title .line {
    height: 2px;
    width: 240px;
    background-color: #ffd600;
  }

  .six__block_part .part .block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .six__block_part .part .block .next {
    display: none;
  }

  .six__block_part .part .block .owl-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .six__block_part .part .block .owl-dots {
    display: none;
  }

  .six__block_part .part .block .owl-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .six__block_part .part .block .owl-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .six__block_part .part .block .owl-nav:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  @media (max-width: 767.98px) {
    .six__block_part .part .block .owl-nav {
      display: none;
    }
  }

  .six__block_part .part .block .owl-nav .owl-prev {
    display: none;
  }

  .six__block_part .part .block .owl-nav .owl-next {
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .six__block_part .part .block .owl-nav .owl-next .icon {
    width: 51px;
    height: 54px;
  }

  @media (max-width: 1199.98px) {
    .six__block_part .part .block .owl-nav .owl-next .icon {
      width: 48px;
      height: 51px;
    }
  }

  @media (max-width: 991.98px) {
    .six__block_part .part .block .owl-nav .owl-next .icon {
      width: 45px;
      height: 48px;
    }
  }

  @media (max-width: 767.98px) {
    .six__block_part .part .block .owl-nav .owl-next .icon {
      width: 42px;
      height: 45px;
    }
  }

  .seven {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ffffff;
  }

  .seven__block {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .seven__block img {
    width: 100%;
  }

  .nine {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    background-color: #dfdfdf;
    height: 1500px;
  }

  .nine img {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    width: 100%;
  }

  .nine__block h1 {
    font-weight: 700;
    font-size: 35px;
    margin: 90px 0px;
    text-align: left;
    padding-left: 50px;
  }

  @media (max-width: 1199.98px) {
    .nine__block h1 {
      margin: 70px 0px;
      font-size: 30px;
    }
  }

  @media (max-width: 991.98px) {
    .nine__block h1 {
      margin: 55px 0px;
      font-size: 29px;
    }
  }

  @media (max-width: 767.98px) {
    .nine__block h1 {
      margin: 40px 0px;
      font-size: 28px;
    }
  }

  @media (max-width: 479.98px) {
    .nine__block h1 {
      margin: 25px 0px;
      font-size: 25px;
    }
  }

  .nine__block_table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nine__block_table .block {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    z-index: 2;
    min-width: 300px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .nine__block_table .block:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .nine__block_table .block:hover .title {
    color: #ffa800;
  }

  @media (max-width: 1199.98px) {
    .nine__block_table .block {
      padding: 28px;
      margin: 18px;
    }
  }

  @media (max-width: 991.98px) {
    .nine__block_table .block {
      padding: 25px;
      margin: 16px;
    }
  }

  @media (max-width: 767.98px) {
    .nine__block_table .block {
      padding: 23px;
      margin: 14px;
    }
  }

  @media (max-width: 479.98px) {
    .nine__block_table .block {
      padding: 20px;
      margin: 10px;
    }
  }

  .nine__block_table .block .title {
    color: black;
    margin-bottom: 35px;
  }

  .nine__block_table .block .title h2 {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }

  @media (max-width: 1199.98px) {
    .nine__block_table .block .title h2 {
      font-size: 19px;
    }
  }

  @media (max-width: 991.98px) {
    .nine__block_table .block .title h2 {
      font-size: 18px;
    }
  }

  @media (max-width: 767.98px) {
    .nine__block_table .block .title h2 {
      font-size: 17 px;
    }
  }

  @media (max-width: 479.98px) {
    .nine__block_table .block .title h2 {
      font-size: 16px;
    }
  }

  .nine__block_table .block p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    max-height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }

  @media (max-width: 1199.98px) {
    .nine__block_table .block p {
      font-size: 16px;
    }
  }

  @media (max-width: 991.98px) {
    .nine__block_table .block p {
      font-size: 12px;
    }
  }

  @media (max-width: 767.98px) {
    .nine__block_table .block p {
      font-size: 11px;
    }
  }

  @media (max-width: 479.98px) {
    .nine__block_table .block p {
      font-size: 10px;
    }
  }

  .nine__block_table .block .more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #2d2d2d;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-right: 5px;
  }

  .nine__block_table .block .more:hover {
    color: gray;
    margin-right: 0px;
  }

  .nine__block_table .block .more:hover svg {
    margin-left: 10px;
  }

  .nine__block_table .block .more .icon {
    width: 0px;
    height: 54px;
    position: relative;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .nine__block_table .block:hover .more .icon {
    width: 51px;
    opacity: 1;
  }

  @media (max-width: 1199.98px) {
    .nine__block_table .block .more .icon {
      width: 48px;
      height: 51px;
    }
  }

  @media (max-width: 991.98px) {
    .nine__block_table .block .more .icon {
      width: 45px;
      height: 48px;
    }
  }

  @media (max-width: 767.98px) {
    .nine__block_table .block .more .icon {
      width: 42px;
      height: 45px;
    }
  }

  .nine__block_table .block .more svg {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-left: 5px;
  }

  .ten {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
  }

  .ten h1 {
    z-index: 2;
    font-size: 75px;
    color: #8d8d8d;
    font-weight: 700;
    position: absolute;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 320px;
    left: -270px;
    margin: 0;
    margin-left: 5%;
    text-transform: uppercase;

    @media (max-width: 1199.98px) {
      font-size: 70px;
    }

    @media (max-width: 991.98px) {
      font-size: 65px;
    }

    @media (max-width: 767.98px) {
      font-size: 60px;
      transform: rotate(0deg);
      top: 0px;
      left: 0px;
      margin-left: 30px;
      position: relative;
      padding-top: 40px;
      font-size: 40px;
      margin-right: auto;
    }

    @media (max-width: 479.98px) {
      font-size: 30px;
      margin-left: 20px;
    }
  }

  .ten__block {
    width: 80%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    padding: 80px 0px 40px 0px;

    @media (max-width: 1199.98px) {
      padding: 70px 0px 30px 0px;
    }

    @media (max-width: 991.98px) {
      padding: 45px 0px 30px 0px;
    }

    @media (max-width: 767.98px) {
      padding: 30px 0px 30px 0px;
    }
    @media (max-width: 467.98px) {
      padding: 0px;
    }
  }

  .ten__block_images {
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 400px;
    width: 100%;

    @media (max-width: 767.98px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
  }

  .ten__block_images .shown {
    position: relative;
    width: 270px;
    height: 380px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ten__block_images .shown .image {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ten__block_images .list {
    position: relative;
    width: 900px;
    height: 380px;
  }

  .ten__block_images .list .image {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ten__block_buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: end;
    align-self: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .ten__block_buttons .left_arrow {
    padding-left: 20px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .ten__block_buttons .left_arrow:hover {
    padding-left: 25px;
  }

  .ten__block_buttons .right_arrow {
    padding-left: 5px;
    padding-right: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .ten__block_buttons .right_arrow:hover {
    padding-left: 10px;
    padding-right: 5px;
  }

  .ten__block_buttons a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    color: black;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ten__block_buttons a .icon {
    width: 41px;
    height: 44px;
    fill: none;
  }

  @media (max-width: 1199.98px) {
    .ten__block_buttons a .icon {
      width: 39px;
      height: 42px;
    }
  }

  @media (max-width: 991.98px) {
    .ten__block_buttons a .icon {
      width: 35px;
      height: 38px;
    }
  }

  @media (max-width: 767.98px) {
    .ten__block_buttons a .icon {
      width: 31px;
      height: 34px;
    }
  }

  .ten__block_buttons a svg {
    margin-left: 5px;
  }

  .ten .line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    padding: 30px 14%;
    background: #ffd600;
    margin-bottom: 60px;

    @media (max-width: 767.98px) {
      padding: 30px;
    }
  }

  .ten .line p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;

    @media (max-width: 1199.98px) {
      font-size: 18px;
    }

    @media (max-width: 991.98px) {
      font-size: 16px;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
    }
  }
  /*# sourceMappingURL=index.css.map */
`;

export default function Home({ state }) {
  const [isLoaded, setLoaded] = useState(
    sessionStorage.getItem("preloader") == null ? false : true
  );
  if (window.scrollY == 0) $("body").css("overflow", "hidden");

  const videoIsLoaded = () => {
    sessionStorage.setItem("preloader", true);
    setLoaded(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeStyled>
      {isLoaded == false ? <Preloader loaded={videoIsLoaded}></Preloader> : ""}
      <div id="page">
        <SideNav />
        <LobbyBlock />
        <NewsBlock state={state} />
        <AboutBlock />
        <CarrierBlock />
        <ChoiceBlock />
        <MapBlock updateData={state.updateData} />
        <EquipmentBlock equipments={state.equipments} />
        <ServicesBlock services={state.services} />
        <CertificatesBlock />
      </div>
    </HomeStyled>
  );
}
