import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import modalBackImg from "./../../../assets/img/MainPage/ModalTwo/back.svg";
import modalFrontImg from "./../../../assets/img/MainPage/ModalTwo/front.png";

const ModalTwoStyles = styled.div`
  display: none;
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #000000 -2.88%, rgba(0, 0, 0, 0) 50%),
    #1d1d1d;

  .modal_close-button {
    position: absolute;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-bottom: 40px;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    z-index: 101;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
    ::before,
    ::after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: #ffc729;
    }
    ::before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    ::after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
    text-align: justify;

    @media (max-width: 1199.98px) {
      font-size: 15px;
    }

    @media (max-width: 991.98px) {
      font-size: 14px;
    }

    @media (max-width: 767.98px) {
      font-size: 13px;
    }

    @media (max-width: 479.98px) {
      font-size: 12px;
    }
  }

  .block {
    position: relative;
    font-family: "Montserrat", sans-serif;
    color: white;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: hidden;
    overflow-y: auto;
    grid-auto-rows: auto auto 1fr;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    padding: 40px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      grid-row: 1/2;
      grid-column: 1/4;
    }

    .info_bottom {
      grid-row: 2/3;
      grid-column: 1/6;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .image {
      position: relative;
      grid-row: 1/2;
      grid-column: 4/6;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .front {
        z-index: 2;
      }
      .back {
        z-index: 1;
      }

      img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .buttons {
      grid-row: 3/4;
      grid-column: 1/6;
      display: flex;
      gap: 20px;
      margin-bottom: 30px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        color: white;
        font-weight: 400;
        padding: 0px 10px;
        text-transform: uppercase;
        border: 1px solid white;
        transition: all 0.2s ease-in-out;

        :hover {
          color: black;
          border: 1px solid #ffc729;
          background: #ffc729;
        }

        @media (max-width: 1199.98px) {
          font-size: 16px;
          height: 38px;
        }

        @media (max-width: 991.98px) {
          font-size: 14px;
          height: 36px;
        }

        @media (max-width: 767.98px) {
          font-size: 13px;
          height: 34px;
        }

        @media (max-width: 479.98px) {
          font-size: 12px;
          height: 32px;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    overflow-y: auto;
    .block {
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 20px;
      padding: 40px 20px 40px 20px;
      .info_bottom {
        gap: 20px;
        margin-bottom: 10px;
      }

      .image {
        height: 300px;

        .front,
        .back {
          height: 100%;
          width: auto;
          max-height: none;
        }
      }
      .buttons {
        flex-direction: column;
        .button {
          max-width: 300px;
        }
      }
    }
  }
`;

export default function ModalTwo() {
  return (
    <ModalTwoStyles className="modal__two">
      <div className="modal_close-button"></div>
      <div className="block">
        <div className="image">
          <img className="front" src={modalFrontImg} alt="front image" />
          <img className="back" src={modalBackImg} alt="back image" />
        </div>
        <div className="info">
          <p>
            ООО «Арктик Энергострой» начала свою деятельность в 2010 году с
            проекта строительства воздушной линии электропередачи 35 кВ «Харьяга
            – ЦПС Инзырей» для нужд ООО «Лукойл-Коми».
          </p>
          <p>
            После успешной реализации проекта, компания заработала авторитет и
            ее стали приглашать к участию в крупных и технически сложных
            проектах Тимано-Печорской нефтегазоносной провинции, например, для
            поставки, шеф-монтажа и пусконаладочных работ трансформаторов типа
            ТДНС 16000/35 в северном исполнении УХЛ 1 для Энергоцентра №2 на
            базе ГТУ ЭГЭС-12 для нужд ООО «СК «РУСВЬЕТПЕТРО».
          </p>
          <p>
            Получив опыт и признание Заказчиков, компания продолжает свою
            деятельность в Республике Коми и Ненецком автономном округе, объем и
            сложность работ с каждым годом увеличивалась, штат компании
            пополнялся квалифицированными, компетентными кадрами, благодаря
            которым ООО «Арктик Энергострой» пополнило свой портфель контрактов
            такими значимыми и статусными как «Поставка котельной с разработкой
            конструкторской документации и выполнением шеф-монтажных и
            пусконаладочных работ, выполнение инспекции давальческого котельного
            оборудования Заказчика», «Выполнение комплекса пусконаладочных работ
            и испытаний оборудования по объекту 'Газотурбинная установка
            выработки электроэнергии' (ГТУ Solar TITAN 130)» и пр.
          </p>
        </div>
        <div className="info_bottom">
          <p>
            Помимо нефтегазовых компаний, ООО «Арктик Энергострой» имеет хороший
            опыт реализации контрактов в энергоснабжении гражданских объектов,
            например участие в проекте внешнего электроснабжения госпиталя в
            Мурманской области для ФГУП «Главное военно-строительное управление
            №14», а так же для атомной промышленности, в проекте поставки модуля
            очистки для Курской АЭС.
          </p>
          <p>
            С более подробной информацией об опыте Компании, оборудовании
            предоставляемых услугах Вы сможете ознакомиться в разделах сайта:
          </p>
        </div>
        <div className="buttons">
          <NavLink className="button" to="/equipments">
            ПРОДУКЦИЯ
          </NavLink>
          <NavLink className="button" to="/services">
            УСЛУГИ И СЕРВИС
          </NavLink>
          <NavLink className="button" to="/projects">
            Проекты
          </NavLink>
        </div>
      </div>
    </ModalTwoStyles>
  );
}
