import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { storage } from "../../../../../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import translitRusEng from "translit-rus-eng";

const EditCategoryStyles = styled.div`
  .edit-category {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      .img-preview {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 200px;
          width: auto;
        }
      }

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function EditCategory({ state, setSection, category }) {
  const [categoryName, setCategoryName] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);

  function cancel() {
    setSection({ action: "Категории" });
  }

  async function updateCategoryImg(upd_category) {
    // need remove img from storage if image was there !
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    await uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        state.products.categories[upd_category].img = url;
      });
    });
  }

  function updateCategoryName(upd_category) {
    state.products.categories[upd_category].name = categoryName;
    var newLink = categoryName.replaceAll("/", "_").replaceAll(" ", "_");
    var link = translitRusEng(newLink, {
      slug: true,
    });
    state.products.categories[upd_category].link = link;
  }

  async function uploadFile() {
    if (imageUpload === null && categoryName === null) return;

    var upd_category = state.products.categories.findIndex(
      (x) => x.id === category.id
    );

    if (imageUpload !== null) {
      await updateCategoryImg(upd_category);
    }
    if (categoryName !== null) {
      updateCategoryName(upd_category);
    }

    setSection({ action: "Категории" });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [imageUpload]);

  return (
    <EditCategoryStyles>
      <div className="edit-category">
        <div className="title">
          <h1>Редактирование категории</h1>
        </div>
        <form>
          <TextField
            id="name"
            label="Название"
            defaultValue={category.name}
            variant="filled"
            onChange={(event) => setCategoryName(event.target.value)}
          />
          {category.img !== null && imageUpload === null ? (
            <div className="img-preview">
              <img src={category.img} />
            </div>
          ) : (
            ""
          )}
          {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}
          <Button variant="contained" component="label">
            {imageUpload === null ? "Загрузить фото" : "Обновить фото"}
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
          </Button>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                imageUpload === null && categoryName === null
                  ? "outlined"
                  : "contained"
              }
            >
              Обновить категорию
            </Button>
            <Button onClick={cancel} variant="contained">
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </EditCategoryStyles>
  );
}
