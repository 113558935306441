import React, { useEffect, useState } from "react";
import img13 from "./../../assets/svg/home/details_black.svg";
import img14 from "./../../assets/img/Customers/background.png";

import styled from "styled-components";
import CustomersCarousel from "../../components/CustomersCarousel/CustomersCarousel";
import Modal from "../../components/Modal/Modal";
import { FBFileManager } from "../../firebase/FileManager";
import { NavLink } from "react-router-dom";
import { addCustomer, getCustomers } from "../../firebase/Firebase";

const CustomersStyles = styled.div`
  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .background_image {
      z-index: 1;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .right_line {
      position: absolute;
      width: 35px;
      background-color: #ffd600;
      height: 50%;
      z-index: 10;
      top: 0;
      right: 0;
    }

    .section__block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(50%, white),
        to(#dedede)
      );
      background: linear-gradient(180deg, white 50%, #dedede 100%);
      padding: 0px 50px;

      .container {
        position: relative;
        margin: 0px 50px;
        max-width: 1200px;
        height: 100%;
        width: 100%;

        .block {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 40px;
          z-index: 2;
          padding-top: 50px;

          h1 {
            font-family: "Montserrat", sans-serif;
            font-size: 35px;
            font-weight: 700;
            text-transform: uppercase;
          }
          &__info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 60px;

            .owl-item {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              h2 {
                font-family: "Montserrat", sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 125%;
              }

              a {
                display: flex;
                align-items: center;
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: #2d2d2d;
                transition: 0.2s ease-in-out;
                gap: 20px;

                :hover {
                  color: gray;
                  margin-left: 10px;

                  svg {
                    margin-left: 10px;
                  }
                }

                .icon {
                  width: 51px;
                  height: 54px;
                  fill: none;
                }

                svg {
                  padding-left: 5px;
                  -webkit-transition: 0.2s ease-in-out;
                  transition: 0.2s ease-in-out;
                }
              }

              .line__box {
                position: relative;
                width: 100%;
                margin: 40px 0px;

                .line {
                  position: absolute;
                  width: calc(100% + 1300px);
                  height: 3px;
                  background-color: #ffd600;
                  left: -1300px;
                }
              }
            }

            .cards {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              max-width: 600px;

              .button_next {
                display: flex;
                align-items: center;
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                font-size: 14px;
                color: #2d2d2d;
                transition: 0.2s ease-in-out;
                gap: 10px;

                :hover {
                  color: gray;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    section {
      .right_line {
        width: 30px;
      }
      .section__block {
        .container {
          .block {
            h1 {
              font-size: 30px;
              margin-bottom: 50px;
            }
            &__info {
              gap: 50px;
              .info {
                h2 {
                  font-size: 16px;
                }
                .line__box {
                  margin: 35px 0px;
                  .line {
                  }
                }
                a {
                  font-size: 12px;
                  .icon {
                    width: 48px;
                    height: 51px;
                  }
                }
              }
              .cards {
                max-width: 480px;
              }
            }
          }
        }
      }
      .background_image {
      }
    }
  }

  @media (max-width: 991.98px) {
    section {
      .right_line {
        width: 20px;
      }
      .section__block {
        .container {
          .block {
            h1 {
              font-size: 25px;
              margin-bottom: 40px;
            }
            &__info {
              gap: 40px;
              flex-direction: column;
              .info {
                h2 {
                  font-size: 15px;
                }
                .line__box {
                  margin: 30px 0px;
                  .line {
                  }
                }
                a {
                  .icon {
                    width: 45px;
                    height: 48px;
                  }
                }
              }
              .cards {
                max-width: 100%;
                padding: 0px 60px;
              }
            }
          }
        }
      }
      .background_image {
      }
    }
  }

  @media (max-width: 767.98px) {
    section {
      .right_line {
        display: none;
      }
      .section__block {
        padding: 0px 20px;
        .container {
          margin: 0px 30px;
          .block {
            h1 {
              font-size: 22px;
              margin-bottom: 30px;
            }
            &__info {
              padding-right: 0px;
              .info {
                width: 100%;
                h2 {
                  font-size: 14px;
                }
                .line__box {
                  margin: 25px 0px;
                  .line {
                  }
                }
                a {
                  .icon {
                    width: 42px;
                    height: 45px;
                  }
                }
              }
              .cards {
                padding: 0px 40px;
              }
            }
          }
        }
      }
      .background_image {
      }
    }
  }

  @media (max-width: 479.98px) {
    section {
      .right_line {
      }
      .section__block {
        .container {
          margin: 0px 20px;
          .block {
            h1 {
              font-size: 20px;
              margin-bottom: 20px;
            }
            &__info {
              .info {
                h2 {
                  font-size: 12px;
                }
                .line__box {
                  margin: 20px 0px;
                  .line {
                  }
                }
                a {
                  .icon {
                  }
                }
              }
              .cards {
                padding: 0px;
              }
            }
          }
        }
      }
      .background_image {
      }
    }
  }
`;

export default function Customers() {
  const [customers, setCustomers] = useState(null);
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  async function downloadCustomers() {
    setCustomers(await getCustomers());
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (customers == null) {
      downloadCustomers();
    }

    setTimeout(() => {
      setModal(true);
    }, 5000);
  }, []);

  return (
    <CustomersStyles>
      {modal ? <Modal close={closeModal} /> : ""}
      <section>
        <div className="right_line"></div>
        <div className="section__block">
          <div className="container">
            <div className="block">
              <h1>Заказчики</h1>
              <div className="block__info">
                <div className="info">
                  <h2>Заказчики которые нам доверяют</h2>
                  <div className="line__box">
                    <div className="line"></div>
                  </div>
                  <NavLink to="/projects">
                    Реализованные проекты
                    <img className="icon" src={img13} alt="" />
                  </NavLink>
                </div>
                <div className="cards">
                  {customers != null ? (
                    <CustomersCarousel
                      items={customers}
                      params={{ show: 3, scroll: 3, rows: 3 }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background_image">
          <img src={img14} alt="" />
        </div>
      </section>
    </CustomersStyles>
  );
}
