import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
// import { getSubCategories } from "../../../../../firebase/Firebase";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Product from "./Product";
import {
  getProductsByCategoryAndSubcategory,
  deleteProduct,
} from "../../../../../firebase/Firebase";

const ProductsStyles = styled.div`
  nav {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
    .top-nav {
      display: flex;
      gap: 18px;
    }
    ul {
      width: fit-content;
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        font-size: 18px;
        gap: 6px;
        color: gray;
        cursor: pointer;
        :hover {
          color: black;
        }
        svg {
          margin-top: 1px;
          height: 0.9em;
        }
      }
      .disable {
        cursor: auto;
        color: black;
      }
    }
  }

  .products {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
      .add-product {
        .add-main-category-button {
          z-index: 10;
          h2 {
            padding-top: 2px;
            padding-left: 5px;
          }
        }
      }
    }
    .products-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1199.98px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .add-product {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    }
  }
`;

export default function Products({ state, category, subcategory, setSection }) {
  const [products, setProducts] = useState(null);

  function addProduct() {
    setSection({
      action: "Добавить продукт",
      category: category,
      subcategory: subcategory,
    });
  }

  function editProduct(_product) {
    setSection({
      action: "Редактировать продукт",
      subcategory: subcategory,
      category: category,
      product: _product,
    });
  }

  function removeProduct(product) {
    let deleteMessage = `Вы уверенны что хотите удалить подкатегорию ${product.name}?`;
    let isDelete = window.confirm(deleteMessage);
    if (isDelete) {
      deleteProduct(product).then(() => {
        let saveList = state.productsList.filter(
          (_product) => _product.name != product.name
        );
        state.productsList = saveList;
        let list = state.productsList.filter(
          (product) =>
            product.category == category.name &&
            product.subcategory == subcategory.name
        );
        setProducts(list);
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (products == null) {
      if (state.productsList === undefined) {
        state.productsList = [];
        getProductsByCategoryAndSubcategory(
          category.name,
          subcategory.name
        ).then((_products) => {
          state.productsList.push(..._products);
          setProducts(_products);
        });
      } else {
        let list = state.productsList.filter(
          (product) =>
            product.category == category.name &&
            product.subcategory == subcategory.name
        );
        if (list.length == 0) {
          getProductsByCategoryAndSubcategory(
            category.name,
            subcategory.name
          ).then((_products) => {
            state.productsList.push(..._products);
            setProducts(_products);
          });
        } else {
          setProducts(list);
        }
      }
    }
  }, [products]);

  return (
    <ProductsStyles>
      <nav>
        <ul className="top-nav">
          <li onClick={() => setSection({ action: "Категории" })}>
            Категории
            <ArrowForwardIcon />
          </li>
          <li
            onClick={() =>
              setSection({ action: "Подкатегории", category: category })
            }
          >
            {category.name}
            <ArrowForwardIcon />
          </li>
          <li className="disable">{subcategory.name}</li>
        </ul>
        <ul>
          <li
            onClick={() =>
              setSection({ action: "Подкатегории", category: category })
            }
          >
            <ArrowBackIcon />
            Назад
          </li>
        </ul>
      </nav>

      <div className="products">
        <div className="title">
          <h1>Продукты</h1>
        </div>
        <div className="products-list">
          <div className="add-product">
            <Fab className="add-button" color="primary" onClick={addProduct}>
              <AddIcon />
            </Fab>
          </div>
          {products != null
            ? products.map((product, index) => (
                <Product
                  key={index}
                  category={category}
                  subcategory={subcategory}
                  product={product}
                  remove={removeProduct}
                  edit={editProduct}
                />
              ))
            : ""}
        </div>
      </div>
    </ProductsStyles>
  );
}
