import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";

import next from "./../../assets/svg/home/details_black.svg";
import loupBlack from "./../../assets/svg/home/loup_black.svg";
import arrowOrange from "./../../assets/svg/arrowOrange.svg";

import translitRusEng from "translit-rus-eng";

import { md1, md3, md4 } from "./../../assets/media-points";

import DropDownList from "../../components/DropDownList/DropDownList";
import ExtraMenu from "../../components/ExtraMenu/ExtraMenu";
import ProductsList from "./ProductsList";

import {
  getAllCategories,
  getProductBySubstring,
  getAllProducts,
} from "../../firebase/Firebase";
import { data } from "jquery";

const EquipnetsStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  .navigation {
    width: 100%;
    display: flex;
    gap: 50px;
    margin-bottom: 40px;

    @media (max-width: ${md3}) {
      flex-direction: column;
      gap: 30px;
    }
    @media (max-width: ${md4}) {
      display: none;
    }

    .path {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-family: "Montserrat", sans-serif;

      .back {
        width: fit-content;

        &_button {
          font-size: 16px;
          background: none;
          display: flex;
          color: black;
          align-items: center;
          gap: 15px;
          cursor: pointer;

          .icon {
            transform: rotate(180deg);
            height: 40%;
            width: 25px;
          }
        }
      }
    }
    .search {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;

      @media (max-width: ${md1}) {
        width: fit-content;
      }
      @media (max-width: ${md3}) {
        margin-bottom: 0px;
        width: 50%;
        justify-content: flex-start;
      }

      &__block {
        width: 50%;
        border-bottom: 1px solid black;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        min-width: 240px;

        @media (max-width: ${md3}) {
          width: 100%;
        }

        input {
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          font-weight: 300;
          width: 100%;
        }
        button {
          background: none;
          cursor: pointer;

          img {
            width: 15px;
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 50px;

    h1 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 35px;
    }
  }

  .search {
    width: 100%;
    margin-bottom: 30px;

    &__block {
      width: 50%;
      border-bottom: 1px solid black;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      min-width: 240px;

      @media (max-width: 767.98px) {
        width: 55%;
      }

      @media (max-width: 479.98px) {
        width: 90%;
      }

      input {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 300;
        width: 100%;
      }
      button {
        background: none;
        cursor: pointer;

        img {
          width: 15px;
        }
      }
    }
  }
  .sort {
    width: 100%;
    display: flex;
    gap: 30px;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
  .products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    .list {
      width: 100%;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(5, 1fr);

      @media (max-width: 1199.98px) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 19px;
      }
      @media (max-width: 991.98px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 18px;
      }

      @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 17px;
      }

      .card {
        position: relative;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 250px;
        min-width: 200px;
        width: auto;

        @media (max-width: 1199.98px) {
          min-width: 167px;
        }
        @media (max-width: 767.98px) {
          min-width: 126px;
        }

        &__info {
          display: flex;
          width: 100%;
          height: 100%;
          position: absolute;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
          padding: 10px;
          padding-bottom: 20px;
          background-color: white;
          text-align: center;
          gap: 20px;

          &:hover {
            border: 3px solid #ffc729;
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            height: fit-content;
            min-height: 100%;
            z-index: 20;

            a {
              display: flex;
              gap: 10px;
            }
          }

          &_img {
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 150px;
            cursor: pointer;

            img {
              width: 100%;
              max-height: 100%;
            }
          }

          &_bottom {
            width: 100%;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-family: "Montserrat", sans-serif;
            gap: 20px;

            h3 {
              font-weight: 600;
              font-size: 12px;
              line-height: 130%;

              @media (max-width: 1199.98px) {
                font-size: 10px;
              }

              @media (max-width: 767.98px) {
                font-size: 9px;
              }

              @media (max-width: 479.98px) {
                font-size: 8px;
              }
            }

            a {
              display: none;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              font-weight: 700;
              color: #ffc729;
              font-size: 12px;
              border: 1px solid #ffc729;
              padding: 10px 14px;
              cursor: pointer;

              @media (max-width: 1199.98px) {
                font-size: 11px;
                padding: 9px 13px;
              }

              @media (max-width: 991.98px) {
                font-size: 10px;
                padding: 8px 11px;
              }

              @media (max-width: 479.98px) {
                font-size: 8px;
                padding: 8px 10px;
              }

              .icon {
                width: 20px;
                height: 10px;
              }
            }
          }
        }
      }
    }
    .button {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;

        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: #2d2d2d;
        transition: 0.2s ease-in-out;
        gap: 20px;

        &:hover {
          padding-left: 10px;
          color: gray;
        }

        img {
          width: 40px;
        }
      }
    }
  }
`;

export default function Equipments({ state }) {
  const [allCategories, setAllCategories] = useState(null);
  const [showCategories, setShowCategories] = useState(10);

  const [allProducts, setAllProducts] = useState(null);
  const [products, setProducts] = useState(null);
  const [searchProducts, setSearchProduts] = useState(null);

  // filter
  const [categories, setCategories] = useState(null);
  const [manufacturers, setManufacturers] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [tFilter, setTFilter] = useState(null);
  const [mFilter, setMFilter] = useState(null);

  let { search } = useParams();

  function addMoreCategories() {
    setShowCategories(showCategories + 5);
  }

  function changeEquipmentType(text) {
    if (text === undefined) {
      if (mFilter != null) {
        setProducts(searchProducts.filter((x) => x.creator === mFilter));
      } else {
        setProducts(searchProducts);
      }
      setTFilter(null);
    } else {
      if (mFilter != null) {
        // update manufacturer list
        setProducts(
          searchProducts.filter(
            (x) => x.subcategory === text && x.subcategory === mFilter
          )
        );
      } else {
        setProducts(searchProducts.filter((x) => x.subcategory === text));
      }
      setTFilter(text);
    }
  }

  function changeManufacturer(text) {
    console.log(text)
    if (text === undefined) {
      if (tFilter != null) {
        setProducts(searchProducts.filter((x) => x.subcategory === tFilter));
      } else {
        setProducts(searchProducts);
      }
      setMFilter(null);
    } else {
      if (tFilter != null) {
        setProducts(
          searchProducts.filter(
            (x) => x.subcategory === tFilter && x.creator === text
          )
        );
      } else {
        setProducts(searchProducts.filter((x) => x.creator === text));
      }
      setMFilter(text);
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 0) {
        let filterProducts = allProducts.filter((product) =>
          product.name.toLowerCase().includes(searchText.toLowerCase())
        );

        let manufacturers = [];
        let categories = [];

        filterProducts.map((product) => {
          if (manufacturers.find((m) => m === product.creator) === undefined) {
            manufacturers.push(product.creator);
          }
          if (categories.find((t) => t === product.subcategory) === undefined) {
            categories.push(product.subcategory);
          }
        });

        setManufacturers(manufacturers);
        setCategories(categories);
        setProducts(filterProducts);
        setSearchProduts(filterProducts);
      }

      if (products != null && searchText.length == 0) setProducts(null);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.history.replaceState("", "", `/equipments/`);

    if (search !== undefined) {
      document.getElementById("search-field").focus();
    }

    if (allCategories == null) {
      getAllCategories().then((data) => {
        setAllCategories(data.sort((a, b) => b.popular - a.popular));
      });
    }
    if (allProducts == null) {
      getAllProducts().then((data) => {
        if (state.produts == undefined) {
          state.products = data;
        }

        setAllProducts(data);
      });
    }
  }, []);

  return (
    <EquipnetsStyles>
      <div className="container">
        <div className="title">
          <h1>Оборудование</h1>
        </div>
        <div className="search">
          <div className="search__block">
            <input
              id="search-field"
              type="text"
              placeholder="Найти в каталоге"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button>
              <img src={loupBlack} alt="лупа" />
            </button>
          </div>
        </div>
        <div className="sort">
          {products != null ? (
            <DropDownList
              type="text"
              list={categories}
              title="Тип оборудования"
              change={changeEquipmentType}
            />
          ) : (
            ""
          )}
          {products != null ? (
            <DropDownList
              type="text"
              list={manufacturers}
              title="Производитель"
              change={changeManufacturer}
            />
          ) : (
            ""
          )}
        </div>
        {products != null ? (
          <ProductsList products={products} />
        ) : (
          <div className="products">
            <div className="list">
              {allCategories != null
                ? allCategories
                    .slice(0, showCategories)
                    .map((category, index) => (
                      <div className="card" key={index}>
                        {category.isSubcategory ? (
                          <div className="card__info">
                            <NavLink
                              className="card__info_img"
                              to={`/equipment-list/${category.link}`}
                            >
                              <img src={category.img} alt="" />
                            </NavLink>
                            <div className="card__info_bottom">
                              <h3>{category.name}</h3>
                              <NavLink to={`/equipment-list/${category.link}`}>
                                Подробнее
                                <img
                                  className="icon"
                                  src={arrowOrange}
                                  alt=""
                                />
                              </NavLink>
                            </div>
                          </div>
                        ) : (
                          <div className="card__info">
                            <NavLink
                              className="card__info_img"
                              to={`/products/${category.link}/${translitRusEng(
                                category.name,
                                { slug: true }
                              ).replaceAll(" ", "_")}`}
                            >
                              <img src={category.img} alt="" />
                            </NavLink>
                            <div className="card__info_bottom">
                              <h3>{category.name}</h3>
                              <NavLink
                                to={`/products/${
                                  category.link
                                }/${translitRusEng(category.name, {
                                  slug: true,
                                }).replaceAll(" ", "_")}`}
                              >
                                Подробнее
                                <img
                                  className="icon"
                                  src={arrowOrange}
                                  alt=""
                                />
                              </NavLink>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                : ""}
            </div>
            <div className="button">
              <button
                onClick={() => {
                  addMoreCategories();
                }}
              >
                Ещё
                <img className="icon" src={next} alt="button" />
              </button>
            </div>
          </div>
        )}

        <ExtraMenu></ExtraMenu>
      </div>
    </EquipnetsStyles>
  );
}
