import React, { useState } from "react";
import styled from "styled-components";

import img1 from "./../../assets/img/MainPage/BlockSeven/map.png";
import img01 from "./../../assets/img/Map/image002.jpg";
import img02 from "./../../assets/img/Map/image003.png";
import img03 from "./../../assets/img/Map/image004.jpg";
import img04 from "./../../assets/img/Map/image005.png";
import img05 from "./../../assets/img/Map/image006.jpg";
import img06 from "./../../assets/img/Map/image007.jpg";
import img07 from "./../../assets/img/Map/image008.png";
import img08 from "./../../assets/img/Map/image009.jpg";
import img09 from "./../../assets/img/Map/image011.jpg";
import img10 from "./../../assets/img/Map/image012.jpg";
import img11 from "./../../assets/img/Map/image013.jpg";
import img12 from "./../../assets/img/Map/image014.jpg";
import img13 from "./../../assets/img/Map/image015.jpg";
import img14 from "./../../assets/img/Map/image016.png";
import img15 from "./../../assets/img/Map/image017.jpg";
import img16 from "./../../assets/img/Map/image018.png";

import img17 from "./../../assets/img/Map/image019.jpg";
import img18 from "./../../assets/img/Map/image020.png";
import img19 from "./../../assets/img/Map/image021.jpg";
import img20 from "./../../assets/img/Map/image022.jpg";
import img21 from "./../../assets/img/Map/image023.jpg";
import img22 from "./../../assets/img/Map/image040.jpg";
import img23 from "./../../assets/img/Map/image026.jpg";
import img24 from "./../../assets/img/Map/image028.jpg";
import img25 from "./../../assets/img/Map/image029.jpg";
import img26 from "./../../assets/img/Map/image030.jpg";
import img28 from "./../../assets/img/Map/image031.jpg";

import img27 from "./../../assets/img/Map/image027.jpg";

const StyledMapBlock = styled.section`
  margin: 50px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .cont {
    position: relative;
    .map {
      width: 100%;
      max-width: 1279.86px;
      height: auto;
    }
    .canvas {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      .dot {
        cursor: pointer;
        user-select: none;
        position: relative;
        background: #ffc729;
        width: 1%;
        height: 2%;
        border-radius: 50%;
        :hover {
          box-shadow: 0 0 10px 5px #ffc729;
        }
      }
      .dot1 {
        top: 44%; // align dot on map vertical
        left: 33%; // align dot on map horizontal
      }
      .dot2 {
        top: 61%; // align dot on map vertical
        left: 3%; // align dot on map horizontal
      }
      .dot3 {
        top: 72%; // align dot on map vertical
        left: 44%; // align dot on map horizontal
      }
      .dot4 {
        top: 38%; // align dot on map vertical
        left: 14%; // align dot on map horizontal
      }
      .dot5 {
        top: 60%; // align dot on map vertical
        left: 27%; // align dot on map horizontal
      }
      .dot6 {
        top: 55%; // align dot on map vertical
        left: 34%; // align dot on map horizontal
      }
      .dot7 {
        top: 50%; // align dot on map vertical
        left: 40%; // align dot on map horizontal
      }
      .dot8 {
        top: 28%; // align dot on map vertical
        left: 32%; // align dot on map horizontal
      }
      .dot9 {
        top: 25%; // align dot on map vertical
        left: 15%; // align dot on map horizontal
      }
      .dot10 {
        top: 27%; // align dot on map vertical
        left: 15%; // align dot on map horizontal
      }
      .dot11 {
        top: 47%; // align dot on map vertical
        left: 4%; // align dot on map horizontal
      }
      .dot12 {
        top: 25%; // align dot on map vertical
        left: 15%; // align dot on map horizontal
      }
      .dot13 {
        top: 23%; // align dot on map vertical
        left: 14%; // align dot on map horizontal
      }
      .dot14 {
        top: 22%; // align dot on map vertical
        left: 16%; // align dot on map horizontal
      }
      .dot15 {
        top: -7%; // align dot on map vertical
        left: 26%; // align dot on map horizontal
      }
      .dot16 {
        top: 12%; // align dot on map vertical
        left: 14%; // align dot on map horizontal
      }
      .dot17 {
        top: 17%; // align dot on map vertical
        left: 9%; // align dot on map horizontal
      }
      .dot18 {
        top: 18%; // align dot on map vertical
        left: 42%; // align dot on map horizontal
      }
      .dot19 {
        top: 14%; // align dot on map vertical
        left: 74%; // align dot on map horizontal
      }
      .dot20 {
        top: 5%; // align dot on map vertical
        left: 13%; // align dot on map horizontal
      }
      .dot21 {
        top: 17%; // align dot on map vertical
        left: 55%; // align dot on map horizontal
      }
      .dot22 {
        top: 45%; // align dot on map vertical
        left: 58%; // align dot on map horizontal
      }
      .dot23 {
        top: 36%; // align dot on map vertical
        left: 39%; // align dot on map horizontal
      }
      .dot24 {
        top: 6%; // align dot on map vertical
        left: 23%; // align dot on map horizontal
      }
      .dot25 {
        top: 4%; // align dot on map vertical
        left: 8%; // align dot on map horizontal
      }
      .dot26 {
        top: 28%; // align dot on map vertical
        left: 77%; // align dot on map horizontal
      }
      .dot27 {
        top: -7%; // align dot on map vertical
        left: 13%; // align dot on map horizontal
      }
      .dot28 {
        top: -12%; // align dot on map vertical
        left: 33%; // align dot on map horizontal
      }
      .dot29 {
        top: -18%; // align dot on map vertical
        left: 31%; // align dot on map horizontal
      }
    }
  }
`;

const StyledInfo = styled.div`
  opacity: ${({ shown }) => (shown ? `1` : "0")};
  transition: opacity 0.5s ease;
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 201.62px;
  display: flex;
  align-items: center;
  top: ${({ top }) => (top ? `-150px` : "50px")};

  .img-block {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    width: 125px;
    height: 125px;
    overflow: hidden;
    border-radius: 50%;
    background: white;

    @media (max-width: 900px) {
      width: 90px;
      height: 90px;
    }

    img {
      z-index: 4;
      position: absolute;
      width: auto;
      max-height: 115px;
      max-width: 115px;

      @media (max-width: 900px) {
        max-height: 90px;
        max-width: 90px;
      }
    }
  }
  .texBlock {
    z-index: 2;
    margin-left: 120px;
    position: absolute;
    display: flex;
    align-items: center;
    height: auto;
    width: 0px;
    overflow: hidden;
    background: #ffd600;
    animation: ${({ shown }) => (shown ? `slideRight 0.5s forwards ease` : "")};
    transition: all 0.8s ease;

    @media (max-width: 900px) {
      margin-left: 85px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.015em;
      min-width: 150px;
      color: #000000;
    }
  }
  @keyframes slideRight {
    0% {
      width: 0px;
      padding: 10px 0px 10px 0px;
    }
    100% {
      width: fit-content;
      padding: 10px 8px 10px 14px;
    }
  }
`;

function HoveredInfo({ text, facility, top, shown }) {
  if (window.innerWidth < 768) return;
  return (
    <StyledInfo top={top} shown={shown}>
      <div className="img-block">
        <img src={facility} alt="" />
      </div>
      <div className="texBlock">
        <p>{text}</p>
      </div>
    </StyledInfo>
  );
}

export default function MapBlock({ updateData }) {
  const [info1, setInfo1] = useState(false);
  const [info2, setInfo2] = useState(false);
  const [info3, setInfo3] = useState(false);
  const [info4, setInfo4] = useState(false);
  const [info5, setInfo5] = useState(false);
  const [info6, setInfo6] = useState(false);
  const [info7, setInfo7] = useState(false);
  const [info8, setInfo8] = useState(false);
  const [info9, setInfo9] = useState(false);
  const [info10, setInfo10] = useState(false);
  const [info11, setInfo11] = useState(false);
  const [info12, setInfo12] = useState(false);
  const [info13, setInfo13] = useState(false);
  const [info14, setInfo14] = useState(false);
  const [info15, setInfo15] = useState(false);
  const [info16, setInfo16] = useState(false);
  const [info17, setInfo17] = useState(false);
  const [info18, setInfo18] = useState(false);
  const [info19, setInfo19] = useState(false);
  const [info20, setInfo20] = useState(false);
  const [info21, setInfo21] = useState(false);
  const [info22, setInfo22] = useState(false);
  const [info23, setInfo23] = useState(false);
  const [info24, setInfo24] = useState(false);
  const [info25, setInfo25] = useState(false);
  const [info26, setInfo26] = useState(false);
  const [info27, setInfo27] = useState(false);
  const [info28, setInfo28] = useState(false);
  const [info29, setInfo29] = useState(false);

  const text1 = "ООО «ЗАРУБЕЖНЕФТЬ – добыча Харьяга»";
  const text2 = "АО «ОТЭКО»";
  const text3 = "ООО «СИБУР»";
  const text4 = "ООО «МИП-Строй №1»";
  const text5 = "ПАО «Мечел»";
  const text6 = "ООО «ЗапСибНефтехим»";
  const text7 = "АО «СибурТюменьГаз»";
  const text8 = "ООО «НЭТМЕХАНИК АБС»";
  const text9 = "Московская областная таможня";
  const text10 = "ЦЕНТРАЛЬНАЯ БАЗОВАЯ ТАМОЖНЯ";
  const text11 = "СЛУЖБА ОХРАНЫ НА КАВКАЗЕ ФЕДЕРАЛЬНОЙ";
  const text12 = "ФГУП «Главное военно-строительное управление";
  const text13 = "Федеральное агентство железнодорожного транспорта";
  const text14 = "Прокуратура Московской области";
  const text15 = "АО «Мурманский морской торговый порт»";
  const text16 = "Центральная таможня (Кинологического центра ФТС России)";
  const text17 = "Филиал АО «Концерн Росэнергоатом»";
  const text18 = "АО «Роспан Интернешнл»";
  const text19 = "ООО «ПРОЕКТБИЗНЕССТРОЙ»";
  const text20 = "ООО ИК «СИБИНТЕК»";
  const text21 = "ООО «Славнефть-Красноярскнефтегаз»";
  const text22 = "АО «Ангарская нефтехимическая компания» ";
  const text23 = "ООО «Энергосервис»";
  const text24 = `ПАО "Криогенмаш"`;
  const text25 = "Филиал ОАО «Белэлектромонтажналадка» в г. Москве";
  const text26 = "ЗАО «Аэродромы Мосты Дороги»";
  const text27 = "Филиал СУ № 111 ФГУП «ГУССТ №1 при Спецстрое России»";
  const text28 = "ООО «ГК ЭФЭСК»";

  return (
    <StyledMapBlock>
      <div className="cont">
        <img className="map" src={img1} alt="" />
        <div className="canvas">
          <div
            className="dot dot1"
            onMouseOver={() => setInfo1(true)}
            onMouseLeave={() => setInfo1(false)}
            onDoubleClick={() => updateData(text1)}
          >
            <HoveredInfo
              shown={info1}
              text={text1}
              facility={img01}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot2"
            onMouseOver={() => setInfo2(true)}
            onMouseLeave={() => setInfo2(false)}
            onDoubleClick={() => updateData(text2)}
          >
            <HoveredInfo
              shown={info2}
              text={text2}
              facility={img02}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot3"
            onMouseOver={() => setInfo3(true)}
            onMouseLeave={() => setInfo3(false)}
            onDoubleClick={() => updateData(text3)}
          >
            <HoveredInfo
              shown={info3}
              text={text3}
              facility={img03}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot4"
            onMouseOver={() => setInfo4(true)}
            onMouseLeave={() => setInfo4(false)}
            onDoubleClick={() => updateData(text4)}
          >
            <HoveredInfo
              shown={info4}
              text={text4}
              facility={img04}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot5"
            onMouseOver={() => setInfo5(true)}
            onMouseLeave={() => setInfo5(false)}
            onDoubleClick={() => updateData(text5)}
          >
            <HoveredInfo
              shown={info5}
              text={text5}
              facility={img05}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot6"
            onMouseOver={() => setInfo6(true)}
            onMouseLeave={() => setInfo6(false)}
            onDoubleClick={() => updateData(text6)}
          >
            <HoveredInfo
              shown={info6}
              text={text6}
              facility={img06}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot7"
            onMouseOver={() => setInfo7(true)}
            onMouseLeave={() => setInfo7(false)}
            onDoubleClick={() => updateData(text7)}
          >
            <HoveredInfo
              shown={info7}
              text={text7}
              facility={img27}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot8"
            onMouseOver={() => setInfo8(true)}
            onMouseLeave={() => setInfo8(false)}
            onDoubleClick={() => updateData(text8)}
          >
            <HoveredInfo
              shown={info8}
              text={text8}
              facility={img07}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot9"
            onMouseOver={() => setInfo9(true)}
            onMouseLeave={() => setInfo9(false)}
            onDoubleClick={() => updateData(text9)}
          >
            <HoveredInfo
              shown={info9}
              text={text9}
              facility={img08}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot10"
            onMouseOver={() => setInfo10(true)}
            onMouseLeave={() => setInfo10(false)}
            onDoubleClick={() => updateData(text9)}
          >
            <HoveredInfo
              shown={info10}
              text={text10}
              facility={img09}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot11"
            onMouseOver={() => setInfo11(true)}
            onMouseLeave={() => setInfo11(false)}
            onDoubleClick={() => updateData(text11)}
          >
            <HoveredInfo
              shown={info11}
              text={text11}
              facility={img10}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot12"
            onMouseOver={() => setInfo12(true)}
            onMouseLeave={() => setInfo12(false)}
            onDoubleClick={() => updateData(text12)}
          >
            <HoveredInfo
              shown={info12}
              text={text12}
              facility={img11}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot13"
            onMouseOver={() => setInfo13(true)}
            onMouseLeave={() => setInfo13(false)}
            onDoubleClick={() => updateData(text13)}
          >
            <HoveredInfo
              shown={info13}
              text={text13}
              facility={img12}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot14"
            onMouseOver={() => setInfo14(true)}
            onMouseLeave={() => setInfo14(false)}
            onDoubleClick={() => updateData(text14)}
          >
            <HoveredInfo
              shown={info14}
              text={text14}
              facility={img13}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot15"
            onMouseOver={() => setInfo15(true)}
            onMouseLeave={() => setInfo15(false)}
            onDoubleClick={() => updateData(text15)}
          >
            <HoveredInfo
              shown={info15}
              text={text15}
              facility={img14}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot16"
            onMouseOver={() => setInfo16(true)}
            onMouseLeave={() => setInfo16(false)}
            onDoubleClick={() => updateData(text16)}
          >
            <HoveredInfo
              shown={info16}
              text={text16}
              facility={img15}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot17"
            onMouseOver={() => setInfo17(true)}
            onMouseLeave={() => setInfo17(false)}
            onDoubleClick={() => updateData(text17)}
          >
            <HoveredInfo
              shown={info17}
              text={text17}
              facility={img16}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot18"
            onMouseOver={() => setInfo18(true)}
            onMouseLeave={() => setInfo18(false)}
            onDoubleClick={() => updateData(text18)}
          >
            <HoveredInfo
              shown={info18}
              text={text18}
              facility={img17}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot19"
            onMouseOver={() => setInfo19(true)}
            onMouseLeave={() => setInfo19(false)}
            onDoubleClick={() => updateData(text19)}
          >
            <HoveredInfo
              shown={info19}
              text={text19}
              facility={img18}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot20"
            onMouseOver={() => setInfo20(true)}
            onMouseLeave={() => setInfo20(false)}
            onDoubleClick={() => updateData(text20)}
          >
            <HoveredInfo
              shown={info20}
              text={text20}
              facility={img19}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot21"
            onMouseOver={() => setInfo21(true)}
            onMouseLeave={() => setInfo21(false)}
            onDoubleClick={() => updateData(text21)}
          >
            <HoveredInfo
              shown={info21}
              text={text21}
              facility={img20}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot22"
            onMouseOver={() => setInfo22(true)}
            onMouseLeave={() => setInfo22(false)}
            onDoubleClick={() => updateData(text22)}
          >
            <HoveredInfo
              shown={info22}
              text={text22}
              facility={img21}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot23"
            onMouseOver={() => setInfo23(true)}
            onMouseLeave={() => setInfo23(false)}
            onDoubleClick={() => updateData(text23)}
          >
            <HoveredInfo
              shown={info23}
              text={text23}
              facility={img22}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot24"
            onMouseOver={() => setInfo24(true)}
            onMouseLeave={() => setInfo24(false)}
            onDoubleClick={() => updateData(text24)}
          >
            <HoveredInfo
              shown={info24}
              text={text24}
              facility={img23}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot25"
            onMouseOver={() => setInfo25(true)}
            onMouseLeave={() => setInfo25(false)}
            onDoubleClick={() => updateData(text25)}
          >
            <HoveredInfo
              shown={info25}
              text={text25}
              facility={img24}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot26"
            onMouseOver={() => setInfo26(true)}
            onMouseLeave={() => setInfo26(false)}
            onDoubleClick={() => updateData(text26)}
          >
            <HoveredInfo
              shown={info26}
              text={text26}
              facility={img25}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot27"
            onMouseOver={() => setInfo27(true)}
            onMouseLeave={() => setInfo27(false)}
            onDoubleClick={() => updateData(text27)}
          >
            <HoveredInfo
              shown={info27}
              text={text27}
              facility={img26}
              top={true} // positioning
            />
          </div>
          <div
            className="dot dot28"
            onMouseOver={() => setInfo28(true)}
            onMouseLeave={() => setInfo28(false)}
            onDoubleClick={() => updateData(text28)}
          >
            <HoveredInfo
              shown={info28}
              text={text28}
              facility={img28}
              top={false} // positioning
            />
          </div>
          <div
            className="dot dot29"
            onMouseOver={() => setInfo29(true)}
            onMouseLeave={() => setInfo29(false)}
            onDoubleClick={() => updateData(text28)}
          >
            <HoveredInfo
              shown={info29}
              text={text28}
              facility={img28}
              top={false} // positioning
            />
          </div>
        </div>
      </div>
    </StyledMapBlock>
  );
}