import React from "react";
import { NavLink } from "react-router-dom";
import img1 from "./../../assets/img/About/background.png";
import img2 from "./../../assets/img/About/logo.png";
import img3 from "./../../assets/svg/home/details_black.svg";

import arrowBlack from "./../../assets/svg/home/arrow_black.svg";

import logo from "./../../assets/img/MainPage/BlockThree/logo_img.png";

import videoMOV from "./../../assets/img/MainPage/BlockThree/video.mov";
import videoMP4 from "./../../assets/img/MainPage/BlockThree/video.mp4";
import videoOGV from "./../../assets/img/MainPage/BlockThree/video.ogv";

import img6 from "./../../assets/img/MainPage/BlockFive/01.jpg";

import styled from "styled-components";
import CarrierBlock from "../../components/CarrierBlock/CarrierBlock";

const AboutUsStyles = styled.div`
  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }
    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  .puzzle {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow: hidden;

    .container {
      width: 100%;
    }

    @media (max-width: 767.98px) {
      .container {
        margin: 0;
      }
    }

    .back {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;

      @media (max-width: 480px) {
        display: none;
      }

      .background-color {
        width: 100%;
        height: 100%;
        background: #e5e5e57a;
      }
    }

    .block {
      display: grid;
      width: 100%;
      grid-template-columns: 250px 250px repeat(2, 1fr);
      padding: 50px 0px 100px 0px;
      font-family: "Montserrat", sans-serif;
      height: fit-content;

      @media (max-width: 1199.98px) {
        grid-template-columns: 200px 200px repeat(2, 1fr);
      }

      @media (max-width: 991.98px) {
        grid-template-columns: 170px 170px repeat(2, 1fr);
        padding: 30px 0px;
      }

      @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 0px;
      }

      .square {
        height: 250px;
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 30px;

        @media (max-width: 1199.98px) {
          height: 200px;
          width: 200px;
          padding: 10px;
        }

        @media (max-width: 991.98px) {
          height: 170px;
          width: 170px;
        }

        @media (max-width: 767.98px) {
          width: auto;
        }

        h1 {
          font-size: 20px;
          font-weight: 700;

          @media (max-width: 1199.98px) {
            font-size: 18px;
          }

          @media (max-width: 991.98px) {
            font-size: 16px;
          }
        }

        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          text-align: center;

          @media (max-width: 1199.98px) {
            font-size: 16px;
          }

          @media (max-width: 991.98px) {
            font-size: 14px;
            line-height: 120%;
          }
        }
      }

      &__expirience {
        grid-column: 2/3;
        grid-row: 1/2;
        background: #ffd600;

        @media (max-width: 767.98px) {
          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
          grid-column: 1/2;
          -ms-grid-row: 1;
          -ms-grid-row-span: 1;
          grid-row: 1/2;
        }
      }

      &__logo {
        grid-column: 1/2;
        grid-row: 2/3;
        background: #c4c4c4;

        @media (max-width: 767.98px) {
          grid-column: 2/3;
          grid-row: 1/3;
        }

        img {
          width: 100%;

          @media (max-width: 767.98px) {
            max-width: 120px;
            width: auto;
          }

          @media (max-width: 479.98px) {
            height: auto;
            width: 100%;
            max-width: 120px;
          }
        }
      }

      &__contracts {
        grid-column: 2/3;
        grid-row: 2/3;

        @media (max-width: 767.98px) {
          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
          grid-column: 1/2;
          -ms-grid-row: 2;
          -ms-grid-row-span: 1;
          grid-row: 2/3;
        }
      }

      &__turnover {
        grid-column: 2/3;
        grid-row: 3/4;
        background: #bfcdff;

        @media (max-width: 767.98px) {
          grid-column: 2/3;
          grid-row: 2/3;
        }
      }

      &__video {
        grid-column: 3/5;
        grid-row: 1/3;
        justify-self: flex-start;
        align-self: flex-end;
        overflow: hidden;
        display: flex;
        height: fit-content;
        width: 100%;

        @media (max-width: 991.98px) {
          max-height: 260px;
        }

        @media (max-width: 767.98px) {
          grid-column: 1/3;
          grid-row: 3/4;
          width: 100%;
        }

        img {
          max-height: 100%;
          width: 100%;
        }

        .video {
          height: 100%;
          width: 100%;
        }
      }

      &__title {
        grid-column: 3/5;
        grid-row: 3/4;
        padding: 50px 0px 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 1199.98px) {
          padding: 35px 0px 0px 25px;
        }

        @media (max-width: 991.98px) {
          padding: 20px 0px 0px 20px;
        }

        @media (max-width: 767.98px) {
          grid-column: 1/3;
          grid-row: 4/5;
          padding: 20px;
        }

        h1 {
          font-weight: 600;
          font-size: 35px;

          @media (max-width: 1199.98px) {
            font-size: 30px;
          }

          @media (max-width: 991.98px) {
            font-size: 25px;
          }

          @media (max-width: 767.98px) {
            margin-bottom: 20px;
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          text-align: justify;

          @media (max-width: 1199.98px) {
            font-size: 15px;
          }

          @media (max-width: 991.98px) {
            font-size: 12px;
          }

          @media (max-width: 767.98px) {
            line-height: 125%;
          }
        }
      }

      &__text {
        grid-column: 2 / 5;
        padding-top: 20px;
        margin-bottom: 30px;

        @media (max-width: 767.98px) {
          padding: 0px 20px 20px 20px;
          grid-column: 1/3;
          grid-row: 5/6;
          margin-bottom: 0;
        }

        p {
          padding: 15px 0px;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          text-align: justify;

          @media (max-width: 1199.98px) {
            font-size: 15px;
            padding: 14px;
          }

          @media (max-width: 991.98px) {
            font-size: 12px;
            padding: 10px;
          }

          @media (max-width: 767.98px) {
            line-height: 125%;
            padding: 0;
          }
        }
      }

      a {
        grid-column: 2/3;
        width: fit-content;
        height: fit-content;
        font-weight: 600;
        color: black;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        gap: 20px;

        @media (max-width: 1199.98px) {
          font-size: 16px;
        }

        @media (max-width: 991.98px) {
          font-size: 14px;
        }

        @media (max-width: 767.98px) {
          font-size: 12px;
          margin-left: 20px;
          grid-column: 1/3;
        }

        @media (max-width: 479.98px) {
          .three .block a {
            font-size: 10px;
          }
        }

        :hover {
          color: gray;
          margin-left: 30px;

          svg {
            margin-left: 30px;
          }
        }

        .icon {
          fill: none;
          width: 51px;
          height: 54px;

          @media (max-width: 1199.98px) {
            width: 48px;
            height: 51px;
          }

          @media (max-width: 991.98px) {
            width: 45px;
            height: 48px;
          }

          @media (max-width: 767.98px) {
            width: 42px;
            height: 45px;
          }
        }

        svg {
          margin-left: 5px;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }

  .about-info {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 4;
    display: flex;
    justify-content: center;

    .background {
      position: absolute;
      left: 0;
      bottom: -70px;
      min-height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: relative;
        width: 100%;
        z-index: 2;
        opacity: 0.4;
      }
    }

    .block {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
      font-family: "Montserrat", sans-serif;
      padding: 80px 0px;

      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        p {
          font-weight: 400;
          line-height: 150%;
          font-size: 18px;
          text-align: justify;
        }
      }
    }

    @media (max-width: 1199.98px) {
      .block {
        padding: 70px 0px;
        &__info {
          p {
            font-size: 16px;
          }
        }
      }
      .background {
        bottom: 0px;
      }
    }

    @media (max-width: 991.98px) {
      .block {
        &__info {
          p {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 767.98px) {
      .block {
        &__info {
          p {
            /* font-size: 20px; */
          }
        }
      }
      .background {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
        }
      }
    }

    @media (max-width: 479.98px) {
      .block {
        &__info {
          p {
            /* font-size: 20px; */
          }
        }
      }
    }
  }
`;

export default function AboutUs() {
  function getWorkingYears() {
    return new Date().getFullYear() - 2010;
  }
  window.scrollTo(0, 0);
  return (
    <AboutUsStyles>
      <div className="puzzle">
        <img className="back" src={img1} alt="" />
        <div className="container">
          <div className="block">
            <div className="block__expirience square">
              <h1>{getWorkingYears()} лет</h1>
              <h3>Опыта</h3>
            </div>
            <div className="block__logo square">
              <img src={img2} alt="" />
            </div>
            <div className="block__contracts square">
              <h1>800+</h1>
              <h3>Успешно реализованных контрактов</h3>
            </div>
            <div className="block__turnover square">
              <h1>4 000 000 000+ ₽</h1>
              <h3>Суммарный оборот компании</h3>
            </div>
            <div className="block__video">
              <video
                muted
                autoPlay
                loop
                playsInline
                src={videoMP4}
                type={"video/mp4"}
                className="video"
              >
                <source src={videoMP4} type="video/mp4" />
                <source src={videoOGV} type="video/ogv" />
                <source src={videoMOV} type="video/mov" />
              </video>
            </div>
            <div className="block__title">
              <p>
                Многолетний опыт лежит в основе всего, что мы делаем. Наша
                команда состоит из высококвалифицированных специалистов,
                способных решать сложные технологические задачи.
              </p>
              <p>
                ООО «Арктик Энергострой» является поставщиком услуг для
                энергетической отрасли с разнообразным портфелем клиентов,
                включая ведущие нефтегазодобывающие компании.
              </p>
            </div>
            <div className="block__text">
              <p>
                Мы проектируем, комплектуем, строим, управляем и поддерживаем
                инфраструктуру для энергетических отраслей. Наше комплексное и
                индивидуальное предложение услуг охватывает каждый этап
                жизненного цикла проекта и различные коммерческие модели,
                предоставляя нашим клиентам необходимую гибкость.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CarrierBlock />
    </AboutUsStyles>
  );
}
