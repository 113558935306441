import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import styled from "styled-components";

const ExtraMenuStyles = styled.div`
  display: none;
  .menu {
    width: 100%;
    padding: 100px 0px 80px 0px;
    font-family: "Montserrat", sans-serif;

    .nav {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 991.98px) {
        gap: 10px;
      }
      @media (max-width: 767.98px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 479.98px) {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      &__column {
        @media (max-width: 767.98px) {
          margin-bottom: 30px;
        }
        @media (max-width: 479.98px) {
          margin-bottom: 10px;
        }
        &:hover {
          .nav__column_title a {
            color: #ffd600;
          }
        }

        &_title {
          margin-bottom: 20px;
          a {
            font-weight: 400;
            font-size: 18px;
            color: #2a2b2e;
          }
        }
        &_list {
          @media (max-width: 479.98px) {
            display: none;
          }

          li {
            a,
            .link {
              font-weight: 400;
              font-size: 12px;
              line-height: 200%;
              color: #4b4c52;

              &:hover,
              .link:hover {
                text-decoration: underline;
              }
            }
            a.disable,
            .link.disable {
              color: #a5a59360;
            }
            a.disable:hover,
            .link.disable:hover {
              cursor: default;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;

export default function ExtraMenu() {
  return (
    <ExtraMenuStyles>
      <menu className="menu">
        <nav className="nav">
          <div className="nav__column">
            <div className="nav__column_title">
              <NavLink to="/equipments">Оборудование</NavLink>
            </div>
            <ul className="nav__column_list">
              <li>
                <NavLink to="/equipment-list/Transformatory">
                  Трансформаторы
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Vzryvozashhishhennoe_i_pozharnoe_oborudovanie/Vzryvozashhishhennoe_i_pozharnoe_oborudovanie">
                  Взрывозащищенное и пожарное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Kabelenesushhie_sistemy/Kabelenesushhie_sistemy">
                  Кабеленесущие системы
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Kontrolno-izmeritelnye_pribory_i_avtomatika_(KIPiA)/Kontrolno-izmeritelnye_pribory_i_avtomatika_(KIPiA)">
                  Контрольно-измерительные приборы и автоматика (КИПиА)
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Setevoe_i_kommutaczionnoe_oborudovanie">
                  Сетевое и коммутационное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Kotelnoe_oborudovanie/Kotelnoe_oborudovanie">
                  Котельное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Kabeli_i_provoda">
                  Кабели и провода
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Zaporno-reguliruyushhaya_armatura/Zaporno-reguliruyushhaya_armatura">
                  Запорно-регулирующая арматура
                </NavLink>
              </li>
              <li>
                <NavLink to="/products/Osvetitelnoe_oborudovanie/Osvetitelnoe_oborudovanie">
                  Осветительное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Emkostnoe_i_teploobmennoe_oborudovanie">
                  Емкостное и теплообменное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Vysokovoltnoe_oborudovanie">
                  Высоковольтное оборудование
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Sistemy_besperebojnogo_pitaniya">
                  Системы бесперебойного питания
                </NavLink>
              </li>
              <li>
                <NavLink to="/equipment-list/Shhitovoe_oborudovanie">
                  Щитовое оборудование
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="nav__column">
            <div className="nav__column_title">
              <NavLink to="/services">Услуги и сервис</NavLink>
            </div>
            <ul className="nav__column_list">
              <li>
                <Link to="/services/EPCM">EPCM</Link>
              </li>
              <li>
                <Link to="/services/ShMR_i_PNR">ШМР и ПНР</Link>
              </li>
              <li>
                <Link to="/services/Proektirovanie">Проектирование</Link>
              </li>
              <li>
                <Link to="/services/Stroitelno-montazhnye_raboty">
                  Строительно-монтажные работы
                </Link>
              </li>
              <li>
                <Link to="/services/Garantijnoe_obsluzhivanie">
                  Гарантийное обслуживание
                </Link>
              </li>
              <li>
                <Link to="/services/Servis">Сервис</Link>
              </li>
              <li>
                <Link to="/services/Kompleksnye_postavki">
                  Комплексные поставки
                </Link>
              </li>
              <li>
                <Link to="/services/Avtomatizacziya_obektov">
                  Автоматизация объектов
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav__column">
            <div className="nav__column_title">
              <NavLink to="/about">О нас</NavLink>
            </div>
            <ul className="nav__column_list">
              <li>
                <Link to="/projects">География проектов</Link>
              </li>
              <li>
                <a href="#" className="disable">
                  Вакансии
                </a>
              </li>
              <li>
                <a href="#" className="disable">
                  Лицензия и сертификаты
                </a>
              </li>
            </ul>
          </div>
          <div className="nav__column">
            <div className="nav__column_title">
              <NavLink to="/contacts">Контакты</NavLink>
            </div>
            <ul className="nav__column_list"></ul>
          </div>
        </nav>
      </menu>
    </ExtraMenuStyles>
  );
}
