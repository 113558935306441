import React from "react";
import styled from "styled-components";

import arrowBlack from "./../../assets/svg/home/arrow_black.svg";

import "./style.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import "swiper/css";

const Column = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 999.98px) {
    align-items: center;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      max-height: 60px;
      max-width: 130px;

      @media (max-width: 991.98px) {
        max-width: 100px;
      }
      @media (max-width: 479.98px) {
        max-width: 70px;
        max-height: 50px;
      }
    }
  }
`;

export default function ChoiceBlockCarousel({ items, showCount }) {
  function getButtonItem(item, index) {
    if (item == undefined) {
      return (
        <a key={index}>
          <img src="" alt="" />
        </a>
      );
    }
    if (item.url == undefined) {
      return (
        <a key={index}>
          <img src={item.img} />
        </a>
      );
    }
    return (
      <a key={index} href={item.url}>
        <img src={item.img} />
      </a>
    );
  }

  function getRows(i) {
    const rows = [];

    rows.push(getButtonItem(items[i], i));
    rows.push(getButtonItem(items[i + 1], i + 1));

    return rows;
  }

  function getCols() {
    if (items.length == 1) {
      return (
        <Column>
          {getButtonItem(items[0], 0)}
          {getButtonItem(undefined, 1)}
        </Column>
      );
    } else {
      const cols = [];
      for (let i = 0, col = 1; i < items.length; i += 2, col++) {
        cols.push(
          <SwiperSlide key={col} className=".slide">
            <Column>{getRows(i)}</Column>
          </SwiperSlide>
        );
      }

      return cols;
    }
  }

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={showCount.comp}
      breakpoints={{
        0: {
          slidesPerView: showCount.mobile,
        },
        768: {
          slidesPerView: showCount.tablet,
        },
        1200: {
          slidesPerView: showCount.tabletBig,
        },
      }}
      navigation={{ nextEl: ".swiper-button-next" }}
      modules={[Navigation]}
    >
      {getCols()}
      <div id="btn" className="swiper-button-next">
        <img src={arrowBlack} alt="" className="arrow" />
      </div>
    </Swiper>
  );
}
