import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrowBlack from "./../../assets/svg/home/arrow_black.svg";

import { NavLink } from "react-router-dom";

import { getNews } from "./../../pages/News/setNews";

const NewsBlockStyle = styled.div`
  .two {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#0c0c0c),
        color-stop(34.75%, #0c0c0c),
        color-stop(86.82%, #323232)
      ),
      #323232;
    background: linear-gradient(
        180deg,
        #0c0c0c 0%,
        #0c0c0c 34.75%,
        #323232 86.82%
      ),
      #323232;

    h1 {
      z-index: 2;
      font-family: "Montserrat", sans-serif;
      font-size: 120px;
      color: #ffd600;
      font-weight: bold;
      position: absolute;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      top: 190px;
      left: -250px;
      margin: 0;
      margin-left: 5%;

      @media (max-width: 1199.98px) {
        font-size: 100px;
        left: -200px;
        top: 150px;
      }
      @media (max-width: 767.98px) {
        font-size: 80px;
        left: -160px;
        top: 120px;
      }
      @media (max-width: 479.98px) {
        font-size: 50px;
        left: -95px;
        top: 75px;
      }
    }

    .white-list {
      position: absolute;
      left: 0px;
      z-index: 1;
      height: 100%;
      background-color: white;
      width: 60%;

      @media (max-width: 1199.98px) {
        width: 70%;
      }

      @media (max-width: 991.98px) {
        width: 80%;
      }
    }
    &__block {
      position: relative;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 50px;
      gap: 20px;
      padding-left: 12%;

      &_column {
        position: relative;
        z-index: 2;

        .column {
          position: relative;
          display: flex;
          align-items: center;
          gap: 30px;

          @media (max-width: 767.98px) {
            height: auto;
            flex-direction: column;
          }

          &__title {
            width: 40%;
            display: flex;
            align-items: center;
            font-family: "Inter", sans-serif;
            padding: 20px 0px 20px 0px;
            background-color: white;

            @media (max-width: 767.98px) {
              width: 100%;
            }

            .info {
              height: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;

              h2 {
                font-weight: 700;
                color: #3a3c41;
                margin-bottom: 20px;
                font-size: 24px;

                @media (max-width: 1199.98px) {
                  font-size: 22px;
                }
                @media (max-width: 991.98px) {
                  font-size: 18px;
                }
                @media (max-width: 767.98px) {
                  font-size: 16px;
                  margin-bottom: 18px;
                }
                @media (max-width: 479.98px) {
                  font-size: 14px;
                  margin-bottom: 15px;
                }
              }

              h3 {
                font-weight: 400;
                color: #3a3c41;
                font-size: 16px;
                line-height: 187%;

                @media (max-width: 1199.98px) {
                  font-size: 14px;
                }
                @media (max-width: 991.98px) {
                  font-size: 12px;
                }
                @media (max-width: 767.98px) {
                  font-size: 11px;
                }
                @media (max-width: 479.98px) {
                  font-size: 10px;
                }
              }
            }

            a {
              display: none;

              @media (max-width: 767.98px) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 120px;
                margin: 0px 10px;
                z-index: 2;
                background-color: white;
              }
              @media (max-width: 479.98px) {
                width: 100px;
              }
            }
          }

          &__image {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;

            @media (max-width: 767.98px) {
              display: none;
            }

            a {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
              z-index: 2;
              border-radius: 0.43rem;
              transition: all 0.2s ease-in-out;

              :hover {
                padding-left: 6px;
              }

              @media (max-width: 767.98px) {
                display: none;
              }
            }

            img {
              z-index: 1;

              @media (max-width: 767.98px) {
                position: absolute;
                width: 100%;
                bottom: 0px;
              }
              @media (max-width: 479.98px) {
                position: relative;
                top: 0px;
              }
            }

            .post-img {
              left: 0px;
              height: 100%;
              width: auto;
            }
          }
          &__image.empty {
            @media (max-width: 767.98px) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default function NewsBlock({ state }) {
  const [news, setNews] = useState(null);

  useEffect(() => {
    if (news == null) {
      if (state.news == null) {
        getNews().then((data) => {
          state.news = data;
          setNews(data);
        });
      } else {
        setNews(state.news);
      }
    }
  }, []);

  return (
    <NewsBlockStyle>
      <section className="two">
        <h1>Новости</h1>
        <div className="white-list"></div>
        <div className="container">
          <div className="two__block">
            {news != null
              ? news.map((post, index) => (
                  <div className="two__block_column" key={index}>
                    <div className="column">
                      <div className="column__title">
                        <div className="info">
                          <h2>{post.title}</h2>
                          <h3>{post.short}</h3>
                        </div>
                        <NavLink to={`/news/${post.postID}`}>
                          <img src={arrowBlack} alt="arrow" />
                        </NavLink>
                      </div>
                      <div className="column__image">
                        <NavLink to={`/news/${post.postID}`}>
                        <img className="icon" src={arrowBlack} alt="" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </section>
    </NewsBlockStyle>
  );
}
