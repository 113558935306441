import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { addService } from "../../../../firebase/Firebase";
import translitRusEng from "translit-rus-eng";

const AddServiceStyles = styled.div`
  .add-service {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;

      .data {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .controller-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function AddService({ state, setSection }) {
  const [service, setService] = useState({
    name: null,
    title: null,
    data: [],
    link: null,
  });

  const handleChange = (value) => {
    setService((service) => ({
      ...service,
      ...value,
    }));
  };

  function uploadFile() {
    var newLink = service.name.replaceAll("/", "_").replaceAll(" ", "_");
    var link = translitRusEng(newLink, {
      slug: true,
    });
    service.link = link;
    addService(service).then((id) => {
      service.id = id;
      state.services.push(service);
      setSection({ action: "Сервисы" });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AddServiceStyles>
      <div className="add-service">
        <div className="title">
          <h1>Добавление услуги / сервиса</h1>
        </div>
        <form>
          <TextField
            id="name"
            label="Название услуги"
            variant="outlined"
            onChange={(e) => handleChange({ name: e.target.value })}
          />
          <TextField
            id="title"
            label="Заголовок"
            variant="outlined"
            onChange={(e) => handleChange({ title: e.target.value })}
          />
          <div className="data">
            <h2>Описание услуги / сервиса:</h2>
            {service.data.map((paragraph, index) => {
              return (
                <TextField
                  key={index}
                  label="Параграф"
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={(e) => {
                    service.data[index] = e.target.value;
                    let newList = [...service.data];
                    handleChange({ data: newList });
                  }}
                />
              );
            })}
            <div className="controller-buttons">
              <Button
                sx={{ maxWidth: "30px" }}
                onClick={() => {
                  if (service.data.length == 0) return;
                  let list = [...service.data];
                  list.pop();
                  handleChange({ data: list });
                }}
                variant="contained"
              >
                -
              </Button>
              <Button
                sx={{ maxWidth: "30px" }}
                onClick={() => {
                  let list = [...service.data];
                  list.push("");
                  handleChange({ data: list });
                }}
                variant="contained"
              >
                +
              </Button>
            </div>
          </div>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                service.name == null || service.title == null
                  ? "outlined"
                  : "contained"
              }
            >
              Добавить услугу
            </Button>
            <Button
              onClick={() => setSection({ action: "Сервисы" })}
              variant="contained"
            >
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </AddServiceStyles>
  );
}
