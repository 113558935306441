import React from "react";
import arrowBlack from "./../../assets/svg/home/arrow_black.svg";
import img1 from "./../../assets/img/MainPage/BlockFive/01.jpg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const CarrierBlockStyles = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffd600;

  .image {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      width: auto;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      max-height: none;
    }
  }

  @media (max-width: 767.98px) {
    display: grid;
    grid-template-rows: 1fr 1fr;

    .container {
      width: auto !important;
    }
    .image {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .block {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media (max-width: 767.98px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0px;
    }

    &__info {
      font-family: "Montserrat", sans-serif;
      grid-column: 1/2;
      padding: 50px 20px 50px 0px;

      @media (max-width: 767.98px) {
        width: 100%;

        .container {
          width: auto;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 50px;
        line-height: 127%;
        margin-bottom: 55px;

        @media (max-width: 1199.98px) {
          font-size: 50px;
          margin-bottom: 40px;
        }

        @media (max-width: 991.98px) {
          font-size: 30px;
        }

        @media (max-width: 767.98px) {
          font-size: 20px;
        }

        @media (max-width: 479.98px) {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }

      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 194%;
        margin-bottom: 55px;
        text-align: justify;

        @media (max-width: 1199.98px) {
          font-size: 16px;
          margin-bottom: 40px;
        }

        @media (max-width: 991.98px) {
          font-size: 12px;
        }

        @media (max-width: 479.98px) {
          margin-bottom: 20px;
        }
      }

      a {
        width: fit-content;
        font-weight: 600;
        color: black;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        gap: 20px;

        :hover {
          color: gray;
          margin-left: 10px;

          svg {
            margin-left: 10px;
          }
        }

        @media (max-width: 1199.98px) {
          font-size: 16px;
        }

        @media (max-width: 991.98px) {
          font-size: 14px;
        }

        @media (max-width: 767.98px) {
          font-size: 12px;
        }

        @media (max-width: 479.98px) {
          font-size: 10px;
        }

        .icon {
          fill: none;
          width: 51px;
          height: 54px;

          @media (max-width: 1199.98px) {
            width: 48px;
            height: 51px;
          }

          @media (max-width: 991.98px) {
            width: 45px;
            height: 48px;
          }

          @media (max-width: 767.98px) {
            width: 42px;
            height: 45px;
          }
        }

        svg {
          margin-left: 5px;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
`;

export default function CarrierBlock() {
  return (
    <CarrierBlockStyles>
      <div className="container">
        <div className="block">
          <div className="block__info">
            <h1>Карьера в Arctic Energostroy</h1>
            <p>
              Мы всегда рады принять в нашу команду талантливых и трудолюбивых
              специалистов. Если вы мечтаете об интересной работе, которая
              позволит раскрыть ваши профессиональные возможности, мы ждем вас!
            </p>
            <NavLink to="/vacancies">
              Подробнее
              <img className="icon" src={arrowBlack} alt="" />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="image">
        <img src={img1} alt="" />
      </div>
    </CarrierBlockStyles>
  );
}
