import React, { useState } from "react";

import Categories from "./Categories/Categories";
import AddCategory from "./Categories/AddCategory";
import EditCategory from "./Categories/EditCategory";
import Subcategories from "./Subcategories/Subcategories";
import AddSubcategory from "./Subcategories/AddSubcategory";
import EditSubcategory from "./Subcategories/EditSubcategory";
import Products from "./Products/Products";
import AddProduct from "./Products/AddProduct";
import EditProduct from "./Products/EditProduct";

export default function ProductsController({ state }) {
  const [section, setSection] = useState({
    action: "Категории",
    category: null,
    subcategory: null,
    products: null,
  });

  function getSection() {
    switch (section.action) {
      case "Категории":
        return <Categories state={state} setSection={setSection} />;
      case "Добавить категорию":
        return <AddCategory state={state} setSection={setSection} />;
      case "Редактировать категорию":
        return (
          <EditCategory
            state={state}
            setSection={setSection}
            category={section.category}
          />
        );
      case "Подкатегории":
        return (
          <Subcategories
            state={state}
            category={section.category}
            setSection={setSection}
          />
        );
      case "Добавить подкатегорию":
        return (
          <AddSubcategory
            state={state}
            category={section.category}
            setSection={setSection}
          />
        );
      case "Редактировать подкатегорию":
        return (
          <EditSubcategory
            state={state}
            category={section.category}
            subcategory={section.subcategory}
            setSection={setSection}
          />
        );
      case "Продукты":
        return (
          <Products
            state={state}
            category={section.category}
            subcategory={section.subcategory}
            setSection={setSection}
          />
        );
      case "Добавить продукт":
        return (
          <AddProduct
            state={state}
            category={section.category}
            subcategory={section.subcategory}
            setSection={setSection}
          />
        );
      case "Редактировать продукт":
        return (
          <EditProduct
            state={state}
            category={section.category}
            subcategory={section.subcategory}
            _product={section.product}
            setSection={setSection}
          />
        );
    }
  }

  return getSection();
}
