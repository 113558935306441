import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllCategories,
  getSubCategories,
} from "../../../../../firebase/Firebase";

import Category from "./Category";

const CategoriesStyles = styled.div`
  .categories {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
      .add-category {
        .add-main-category-button {
          z-index: 10;
          h2 {
            padding-top: 2px;
            padding-left: 5px;
          }
        }
      }
    }
    .category-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1199.98px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .add-category {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
    }
  }
`;

export default function Categories({ state, setSection }) {
  const [categories, setCategories] = useState(null);

  function openSubcategories(_category) {
    setSection({ action: "Подкатегории", category: _category });
  }

  function addCategory() {
    setSection({ action: "Добавить категорию", name: "" });
  }

  function editCategory(_category) {
    setSection({ action: "Редактировать категорию", category: _category });
  }

  function removeCategory(name) {
    let deleteMessage = `Вы уверенны что хотите удалить категорию ${name}?`;
    let isDelete = window.confirm(deleteMessage);

    if (isDelete) {
      let _categories = state.products.categories.filter((x) => x.name != name);
      state.products.categories = _categories;
      setCategories(state.products.categories);
    }
  }

  useEffect(() => {
    if (state.products === undefined) {
      state.products = {};
    }

    if (state.products.categories === undefined) {
      state.products.categories = {};
      getAllCategories().then((data) => {
        state.products.categories = data;
        setCategories(state.products.categories);
      });
    } else {
      setCategories(state.products.categories);
    }
  }, [categories]);

  return (
    <CategoriesStyles>
      <div className="categories">
        <div className="title">
          <h1>Категории товаров</h1>
        </div>
        <div className="category-list">
          {/* <div className="add-category">
            <Fab className="add-button" color="primary" onClick={addCategory}>
              <AddIcon />
            </Fab>
          </div> */}
          {categories != null
            ? categories.map((category, index) => (
                <Category
                  key={index}
                  category={category}
                  showSubcategories={openSubcategories}
                  remove={removeCategory}
                  edit={editCategory}
                />
              ))
            : ""}
        </div>
      </div>
    </CategoriesStyles>
  );
}
