import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getSubCategory,
  getCategoryByName,
  getProductByLink,
} from "../../firebase/Firebase";
import { Markup } from "interweave";

import arrow from "./../../assets/svg/services/arrow.svg";
import download from "./../../assets/svg/product/arrowDownload.svg";
import pdf from "./../../assets/img/Products/PDF.jpg";
import ExtraMenu from "../../components/ExtraMenu/ExtraMenu";

import { md1, md2, md3, md4 } from "./../../assets/media-points";

const ProductStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }
  .navigation {
    width: 100%;
    display: flex;
    gap: 50px;
    margin-bottom: 50px;

    @media (max-width: ${md4}) {
      display: none;
    }

    .path {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        li {
          width: fit-content;
          .button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            gap: 15px;
            font-size: 16px;

            &:visited {
              color: black;
            }

            img {
              height: 40%;
              width: 25px;
            }
          }
          .active {
            background: none;
            font-weight: 600;
          }
        }
      }
      .back {
        width: fit-content;
        &_button {
          font-size: 16px;
          background: none;
          display: flex;
          color: black;
          align-items: center;
          gap: 15px;

          .icon {
            transform: rotate(180deg);
            height: 40%;
            width: 25px;
          }
        }
      }
    }
  }
  .product {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: "Montserrat", sans-serif;

    .top {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 40px;
      overflow: hidden;

      @media (max-width: 767.98px) {
        display: flex;
        flex-direction: column-reverse;
      }

      .title {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h1 {
          font-weight: 600;
          font-size: 26px;
          line-height: 130%;

          @media (max-width: 767.98px) {
            font-size: 24px;
          }
          @media (max-width: ${md4}) {
            font-size: 20px;
          }
        }

        p {
          font-size: 18px;
          line-height: 130%;

          @media (max-width: 767.98px) {
            font-size: 16px;
          }
        }
        .product_navigation {
          width: 100%;
          ul {
            display: flex;
            height: 50px;
            width: 100%;
            li {
              height: 100%;
              width: 100%;
              background: #efefef;
              padding: 10px 0px;

              @media (max-width: 767.98px) {
                padding: 0px;
              }

              button {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                background: none;
                padding: 10px;

                @media (max-width: 767.98px) {
                  font-size: 14px;
                  padding: 5px 8px;
                }
              }
            }
            li:not(:last-child) button {
              border-right: 1px solid #c4c4c4;
            }
            li.active:not(:last-child) button {
              border-right: none;
            }
            .active {
              font-weight: 600;
              background: #ffd600;
            }
            li.remove-border button {
              border: none;
            }
          }
        }
      }
      .image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 250px;

        @media (max-width: 767.98px) {
          justify-content: center;
        }

        img {
          max-width: 100%;
          max-height: 250px;
        }
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media (max-width: ${md3}) {
        gap: 30px;
      }

      .purpose {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: ${md3}) {
          gap: 15px;
        }
        h2 {
          font-weight: 600;
          font-size: 18px;

          @media (max-width: ${md4}) {
            font-size: 16px;
          }
        }
        p {
          font-size: 16px;
          line-height: 130%;
          text-align: justify;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
      }

      .characters {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: ${md3}) {
          gap: 15px;
        }
        table {
          th {
            font-weight: 400;
            padding: 5px 10px;
          }
        }
        .table {
          width: fit-content;
          background-color: #efefef;

          table {
            padding: 10px;
            border-collapse: collapse;

            tr {
              border: 1px solid black;

              th {
                border: 1px solid black;
                font-weight: 400;
                padding: 10px 15px;
              }
            }
          }
        }

        h2 {
          font-weight: 600;
          font-size: 18px;

          @media (max-width: ${md4}) {
            font-size: 16px;
          }
        }
        ul {
          width: fit-content;
          min-width: 720px;
          background: #efefef;
          padding: 20px 30px;
          @media (max-width: 767.98px) {
            width: 100%;
            padding: 10px 15px;
            min-width: 0px;
          }
          li {
            display: grid;
            justify-content: center;
            width: 100%;
            grid-template-columns: 45% 1px 53%;
            grid-column-gap: 3px;
            .key {
              display: flex;
              align-items: center;
              font-size: 14px;
              line-height: 130%;
              padding: 10px;
            }
            .line {
              height: 100%;
              width: 1px;
              background: #dbd9dc;
            }
            .value {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              line-height: 130%;
              padding: 10px;
              text-align: center;
              word-break: break-word;
            }
          }
          li:not(:last-child) {
            .key,
            .value {
              border-bottom: 1px solid #dbd9dc;
            }
          }
          li.key-value {
            display: flex;
            width: 100%;
            font-size: 14px;
            line-height: 130%;
            padding: 10px;
            word-break: break-word;
          }
          li:not(:last-child).key-value {
            border-bottom: 1px solid #dbd9dc;
          }
        }
      }

      .conditions {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: ${md3}) {
          gap: 15px;
        }
        h2 {
          font-weight: 600;
          font-size: 18px;

          @media (max-width: ${md4}) {
            font-size: 16px;
          }
        }
        .conditions-body {
          font-size: 16px;
          line-height: 130%;
          text-align: justify;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
      }

      .download {
        display: flex;
        justify-content: flex-start;
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          background: none;
          color: black;
          cursor: pointer;

          :active,
          :focus {
            color: black;
          }

          img {
            @media (max-width: 767.98px) {
              height: 60px;
            }
          }
          .title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-weight: 600;

            .icon {
              padding-top: 2px;
              height: auto;
            }
          }
          h4 {
            font-weight: 300;
          }
        }
      }

      .projects {
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 16px;
        line-height: 130%;
        @media (max-width: ${md4}) {
          font-size: 14px;
        }

        h2 {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 130%;
          @media (max-width: ${md4}) {
            font-size: 16px;
          }
        }

        p {
          font-size: 16px;
          line-height: 130%;
          text-align: justify;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
        h4 {
          color: gray;
          font-size: 16px;
          line-height: 130%;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
        h4:not(:first-child) {
          margin-top: 5px;
        }
        h4:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      .manufacturer-list {
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 130%;
          @media (max-width: ${md4}) {
            font-size: 16px;
          }
        }

        p {
          font-size: 16px;
          line-height: 130%;
          text-align: justify;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
      }
      .manufacturer {
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
          font-size: 18px;
          line-height: 130%;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
        h2:not(:first-child) {
          margin-top: 30px;
          @media (max-width: ${md3}) {
            margin-top: 20px;
          }
        }

        h3 {
          font-size: 17px;
          line-height: 130%;
          @media (max-width: ${md4}) {
            font-size: 15px;
          }
        }

        p {
          font-size: 16px;
          line-height: 130%;
          text-align: justify;
          @media (max-width: ${md4}) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export default function Product() {
  const [data, setProduct] = useState(null);

  const [navigation, setNavigation] = useState("Обзор");

  let { category, productLink } = useParams();

  function getProductData() {
    switch (navigation) {
      case "Обзор":
        return (
          <div className="product">
            <div className="top">
              <div className="title">
                <h1>{data.product.name}</h1>
                <p>{data.product.description}</p>
                <div className="product_navigation">
                  <ul>
                    <li className="active">
                      <button>Обзор</button>
                    </li>
                    <li>
                      <button onClick={() => setNavigation("Проекты")}>
                        Проекты
                      </button>
                    </li>
                    <li>
                      <button onClick={() => setNavigation("Производитель")}>
                        Производитель
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="image">
                <img src={data.product.img} alt="изображение продукта" />
              </div>
            </div>
            <div className="bottom">
              {data.product.purpose != null ? (
                <div className="purpose">
                  <h2>Назначение:</h2>
                  <p>
                    <Markup content={data.product.purpose} />
                  </p>
                </div>
              ) : (
                ""
              )}
              {data.product.characters != null ? (
                <div className="characters">
                  <h2>Основные технические характеристики:</h2>
                  <ul>
                    {data.product.characters != null
                      ? Array.isArray(data.product.characters)
                        ? data.product.characters.map((character, index) => {
                            if (character.value.length == 0) {
                              return (
                                <li key={index} className="key-value">
                                  {character.key}
                                </li>
                              );
                            }
                            return (
                              <li key={index}>
                                <div className="key">{character.key}</div>
                                <div className="line"></div>
                                <Markup
                                  className="value"
                                  content={character.value}
                                />
                              </li>
                            );
                          })
                        : createMap(data.product.characters).map(
                            (character, index) => {
                              if (character.value.length == 0) {
                                return (
                                  <li key={index} className="key-value">
                                    {character.key}
                                  </li>
                                );
                              }
                              return (
                                <li key={index}>
                                  <div className="key">{character.key}</div>
                                  <div className="line"></div>
                                  <Markup
                                    className="value"
                                    content={character.value}
                                  />
                                </li>
                              );
                            }
                          )
                      : ""}
                  </ul>
                  {data.product.tableOneName != null ? (
                    <h2>{data.product.tableOneName}</h2>
                  ) : (
                    ""
                  )}
                  {data.product.tableOne != null ? (
                    <Markup className="table" content={data.product.tableOne} />
                  ) : (
                    ""
                  )}
                  {data.product.tableTwoName != null ? (
                    <h2>{data.product.tableTwoName}</h2>
                  ) : (
                    ""
                  )}
                  {data.product.tableTwo != null ? (
                    <Markup className="table" content={data.product.tableTwo} />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {data.product.conditions != null ? (
                <div className="conditions">
                  <h2>Условия эксплуатации:</h2>
                  <Markup
                    className="conditions-body"
                    content={data.product.conditions}
                  />
                </div>
              ) : (
                ""
              )}
              {data.product.download != null ? (
                <div className="download">
                  <a href={data.product.download} download target="_blank">
                    <img src={pdf} alt="скачать каталог" />
                    <div className="title">
                      Скачать каталог
                      <img className="icon" src={download} alt="" />
                    </div>
                    <h4>(20мб)</h4>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case "Проекты":
        return (
          <div className="product">
            <div className="top">
              <div className="title">
                <h1>{data.product.name}</h1>
                <p>{data.product.description}</p>
                <div className="product_navigation">
                  <ul>
                    <li className="remove-border">
                      <button onClick={() => setNavigation("Обзор")}>
                        Обзор
                      </button>
                    </li>
                    <li className="active">
                      <button>Проекты</button>
                    </li>
                    <li>
                      <button onClick={() => setNavigation("Производитель")}>
                        Производитель
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="image">
                <img src={data.product.img} alt="изображение продукта" />
              </div>
            </div>
            <div className="bottom">
              {data.product.projects != null ? (
                Array.isArray(data.product.projects) ? (
                  data.product.projects.map((project, index) => {
                    return (
                      <div className="projects" key={index}>
                        <Markup
                          content={`<h2><b>${project.customer}</b></h2>`}
                        />
                        <p>
                          <Markup content={project.products} />
                        </p>
                        <Markup content={`<h4>${project.manufacturer}</h4>`} />
                      </div>
                    );
                  })
                ) : (
                  <Markup
                    className="projects"
                    content={data.product.projects}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case "Производитель":
        return (
          <div className="product">
            <div className="top">
              <div className="title">
                <h1>{data.product.name}</h1>
                <p>{data.product.description}</p>
                <div className="product_navigation">
                  <ul>
                    <li>
                      <button onClick={() => setNavigation("Обзор")}>
                        Обзор
                      </button>
                    </li>
                    <li className="remove-border">
                      <button onClick={() => setNavigation("Проекты")}>
                        Проекты
                      </button>
                    </li>
                    <li className="active">
                      <button>Производитель</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="image">
                <img src={data.product.img} alt="изображение продукта" />
              </div>
            </div>
            <div className="bottom">
              {data.product.manufacturer != null ? (
                Array.isArray(data.product.manufacturer) ? (
                  <div className="manufacturer-list">
                    {data.product.creator != null ? (
                      <h2>
                        <b>{data.product.creator}</b>
                      </h2>
                    ) : (
                      ""
                    )}
                    {data.product.manufacturer.map((manufacturer, index) => {
                      return (
                        <p>
                          <Markup
                            key={index}
                            className="manufacturer"
                            content={manufacturer}
                          />
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <Markup
                    className="manufacturer"
                    content={data.product.manufacturer}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        );
    }
  }

  function createMap(obj) {
    const list = [];
    for (const [key, value] of Object.entries(obj)) {
      list.push({ key: key, value: value });
    }

    return list;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductByLink(productLink).then((_product) => {
      getSubCategory(_product.subcategory).then((_subcategory) => {
        getCategoryByName(_product.category).then((_category) => {
          var _productData = {
            product: _product,
            subcategory: _subcategory,
            category: _category,
          };

          window.history.replaceState(
            "",
            "",
            `/product/${_category.link}/${_subcategory.link}/${_product.link}`
          );
          setProduct(_productData);
        });
      });
    });
  }, []);

  return (
    <ProductStyles>
      {data != null ? (
        <div className="container">
          <div className="navigation">
            <div className="path">
              <ul>
                <li>
                  <NavLink className="button" to="/">
                    Главная <img className="icon" src={arrow} alt="" />
                  </NavLink>
                </li>
                <li>
                  <NavLink className="button" to="/equipments">
                    Оборудование <img className="icon" src={arrow} alt="" />
                  </NavLink>
                </li>
                {category != null && category.isSubcategory ? (
                  <li>
                    <NavLink
                      className="button"
                      to={`/equipment-list/${data.category.link}`}
                    >
                      {data.category.name}{" "}
                      <img className="icon" src={arrow} alt="" />
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <NavLink
                    className="button"
                    to={`/products/${data.category.link}/${data.subcategory.link}`}
                  >
                    {data.subcategory.name}{" "}
                    <img className="icon" src={arrow} alt="" />
                  </NavLink>
                </li>
                <li>
                  <button className="button active">
                    {data.product != null ? data.product.name : ""}
                  </button>
                </li>
              </ul>
              <div className="back">
                <NavLink
                  className="back_button"
                  to={`/products/${data.category.link}/${data.subcategory.link}`}
                >
                  <img className="icon" src={arrow} alt="" />
                  Назад
                </NavLink>
              </div>
            </div>
          </div>
          {data.product != null ? getProductData() : ""}
          <ExtraMenu></ExtraMenu>
        </div>
      ) : (
        ""
      )}
    </ProductStyles>
  );
}
