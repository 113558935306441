import styled from "styled-components";
import React, { useEffect } from "react";

import showImage from "./../assets/img/notWorking.jpg";

const NotWorkingPage = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDEDED;
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export default function NotWorking() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <NotWorkingPage>
            <img src={showImage} alt="" />
        </NotWorkingPage>
    );
}
