import React, { useEffect, useState } from "react";
import ChoiceBlockCarousel from "../ChoiceBlockCarousel/ChoiceBlockCarousel";
import { getCustomers, getPartners } from "../../firebase/Firebase";

export default function ChoiceBlock() {
  const [customers, setCustomers] = useState(null);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    if (customers == null) {
      getCustomers().then((list) => {
        setCustomers(list);
      });
    }
    if (partners == null) {
      getPartners().then((list) => {
        setPartners(list);
      });
    }
  }, []);

  return (
    <section className="six">
      <div className="container">
        <div className="six__block">
          <div className="six__block_title">
            <h1>нас выбирают</h1>
          </div>
          <div className="six__block_part">
            <div className="part">
              <div className="part__title">
                <h1>Заказчики</h1>
                <div className="line"></div>
              </div>
              <div className="block">
                {customers != null ? (
                  <ChoiceBlockCarousel
                    items={customers}
                    showCount={{ mobile: 3, tablet: 4, tabletBig: 5, comp: 6 }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="six__block_part">
            <div className="part">
              <div className="part__title">
                <h1>Партнеры</h1>
                <div className="line"></div>
              </div>
              <div className="block">
                {partners != null ? (
                  <ChoiceBlockCarousel
                    items={partners}
                    showCount={{ mobile: 3, tablet: 4, tabletBig: 5, comp: 6 }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
