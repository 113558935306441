import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { storage } from "../../../../../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import translitRusEng from "translit-rus-eng";

const EditSubcategoryStyles = styled.div`
  .edit-subcategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      .img-preview {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 200px;
          width: auto;
        }
      }

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function EditSubcategory({
  state,
  category,
  subcategory,
  setSection,
}) {
  const [subcategoryName, setSubcategoryName] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);

  function cancel() {
    setSection({ action: "Подкатегории", category: category });
  }

  async function updateCategoryImg(upd_subcategory) {
    // need remove img from storage if image was there !
    const imageRef = ref(
      storage,
      `images/subcategories/${imageUpload.name + v4()}`
    );
    await uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        state.products.subcategories[upd_subcategory].img = url;
      });
    });
  }

  function updateCategoryName(upd_subcategory) {
    state.products.subcategories[upd_subcategory].name = subcategoryName;
    var newLink = subcategoryName.replaceAll("/", "_").replaceAll(" ", "_");
    var link = translitRusEng(newLink, {
      slug: true,
    });
    state.products.subcategories[upd_subcategory].link = link;
  }

  async function uploadFile() {
    if (imageUpload === null && subcategoryName === null) return;

    var upd_subcategory= state.products.subcategories.findIndex(
      (x) => x.id === category.id
    );

    if (imageUpload !== null) {
      await updateCategoryImg(upd_subcategory);
    }
    if (subcategoryName !== null) {
      updateCategoryName(upd_subcategory);
    }

    setSection({ action: "Категории", category : category });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [imageUpload]);

  return (
    <EditSubcategoryStyles>
      <div className="edit-subcategory">
        <div className="title">
          <h1>Редактирование подкатегории</h1>
        </div>
        <form>
          <TextField
            id="name"
            label="Название"
            defaultValue={subcategory.name}
            variant="filled"
            onChange={(event) => setSubcategoryName(event.target.value)}
          />
          {subcategory.img !== null && imageUpload === null ? (
            <div className="img-preview">
              <img src={subcategory.img} />
            </div>
          ) : (
            ""
          )}
          {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}
          <Button variant="contained" component="label">
            {imageUpload === null ? "Загрузить фото" : "Обновить фото"}
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
          </Button>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                imageUpload === null && subcategoryName === null
                  ? "outlined"
                  : "contained"
              }
            >
              Обновить подкатегорию
            </Button>
            <Button onClick={cancel} variant="contained">
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </EditSubcategoryStyles>
  );
}
