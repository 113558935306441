import React, { useEffect } from "react";
import styled from "styled-components";
import preloaderMP4 from "../../assets/img/MainPage/Preloader/Preloader.mp4";
import preloaderOGV from "../../assets/img/MainPage/Preloader/Preloader.ogv";
import preloaderMOV from "../../assets/img/MainPage/Preloader/Preloader.mov";

const PreloaderStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .closeModal {
    position: absolute;
    z-index: 101;
    top: 30px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .closeModal:hover {
    transform: scale(1.1);
  }

  .closeModal::before,
  .closeModal::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 24px;
    height: 3px;
    background: #ffd600;
  }

  .closeModal:active:before,
  .closeModal:active:after {
    background: white;
  }

  .closeModal::before {
    transform: rotate(45deg);
  }

  .closeModal::after {
    transform: rotate(-45deg);
  }

  .preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .video {
      min-height: 100%;
      min-width: 100%;

      @media (max-width: 767.98px) {
        height: 100%;
        min-height: unset;
        min-width: unset;
      }
    }
  }
`;

export default function Preloader(props) {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " " || event.key === "Escape") {
      props.loaded();
    }
  };
  const handleMouseDown = (e) => {
    var isRightMB;
    e = e || window.event;

    if ("which" in e)
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
    else if ("button" in e)
      // IE, Opera
      isRightMB = e.button == 2;

    if (isRightMB === true) {
      props.loaded();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseDown);

    // После срабатывания, удаляем обработчик события
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <PreloaderStyle>
      <div className="closeModal" onClick={props.loaded}></div>
      <div className="preloader">
        <video
          muted
          autoPlay
          playsInline
          src={preloaderMP4}
          type={"video/mp4"}
          className="video"
          onEnded={() => props.loaded()}
        >
          <source src={preloaderMP4} type="video/mp4" />
          <source src={preloaderOGV} type="video/ogv" />
          <source src={preloaderMOV} type="video/mov" />
        </video>
      </div>
    </PreloaderStyle>
  );
}
