import img1 from "./../../assets/img/MainPage/BlockTwo/01.jpg";
import img2 from "./../../assets/img/MainPage/BlockTwo/02.png";
import img3 from "./../../assets/img/MainPage/BlockTwo/03.png";

import { getAllNews } from "../../firebase/Firebase";

let imges = [img1, img2, img3];

export async function getNews() {
  let news = [];

  await getAllNews().then((data) => {
    news.push(...data);
    let postID = news.reduce((acc, shot) => acc = acc > shot.postID ? acc : shot.postID, 0) + 1;
    if (news.length < 3) {
      let needAdd = 3 - news.length;
      for (let i = 0, j = postID; i < needAdd; i++, j++) {
        news.push({
          title: "Sed ut perspiciatis",
          short:
            "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia.",
          img: imges[i],
          postID: j
        });
      }
    }
  });

  return news;
}
