import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { addNews, storage } from "../../../../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

const AddNewsStyles = styled.div`
  .add-news {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .full {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .controller-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }
    }
  }
`;

export default function AddNews({ state, setSection, postID }) {
  const [news, setNews] = useState({
    type: 1,
    title: null,
    short: null,
    full: [],
    img: null,
    postID: null,
  });
  const [imageUpload, setImageUpload] = useState(null);

  const handleChange = (value) => {
    setNews((news) => ({
      ...news,
      ...value,
    }));
  };

  function uploadFile() {
    if (
      imageUpload === null ||
      news.title === null ||
      news.short === null ||
      news.full.length === 0
    )
      return;
    const imageRef = ref(storage, `images/news/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        news.img = url;
        news.postID = postID + 1;

        addNews(news).then((id) => {
          news.id = id;
          state.news.push(news);
          setSection({ action: "Новости" });
        });
      });
    });
  }
  
  return (
    <AddNewsStyles>
      <div className="add-news">
        <div className="title">
          <h1>Добавление новости</h1>
        </div>
        <form>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Тип новости</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="type"
              value={news.type}
              label="Age"
              onChange={(e) => handleChange({ type: e.target.value })}
            >
              <MenuItem value={1}>Обычный</MenuItem>
              <MenuItem value={2}>Получение сертификата</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="title"
            label="Заголовок новости"
            variant="outlined"
            onChange={(e) => handleChange({ title: e.target.value })}
          />
          <TextField
            id="short"
            label="Короткое описание"
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => handleChange({ short: e.target.value })}
          />
          <h2>Текст новости:</h2>
          {Array.isArray(news.full) ? (
            <div className="full">
              {news.full.map((paragraph, index) => {
                return (
                  <TextField
                    key={index}
                    fullWidth
                    label="Параграф"
                    defaultValue={paragraph}
                    variant="outlined"
                    multiline
                    rows={4}
                    onChange={(e) => {
                      news.full[index] = e.target.value;
                      let list = [...news.full];
                      handleChange({ full: list });
                    }}
                  />
                );
              })}
              <div className="controller-buttons">
                <Button
                  sx={{ maxWidth: "30px" }}
                  onClick={() => {
                    if (news.full.length == 0) return;
                    let list = [...news.full];
                    list.pop();
                    handleChange({ full: list });
                  }}
                  variant="contained"
                >
                  -
                </Button>
                <Button
                  sx={{ maxWidth: "30px" }}
                  onClick={() => {
                    let list = [...news.full];
                    list.push("");
                    handleChange({ full: list });
                  }}
                  variant="contained"
                >
                  +
                </Button>
              </div>
            </div>
          ) : (
            <TextField
              id="full"
              label="Параграф"
              multiline
              rows={10}
              variant="outlined"
              onChange={(e) => handleChange({ full: e.target.value })}
            />
          )}

          {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}
          <Button variant="contained" component="label">
            {imageUpload === null ? "Загрузить фото" : "Обновить фото"}
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={(e) => {
                setImageUpload(e.target.files[0]);
              }}
            />
          </Button>
          <div className="action-buttons">
            <Button
              onClick={uploadFile}
              variant={
                imageUpload === null ||
                news.title === null ||
                news.short === null ||
                news.full === null
                  ? "outlined"
                  : "contained"
              }
            >
              Добавить новость
            </Button>
            <Button
              onClick={() => setSection({ action: "Новости" })}
              variant="contained"
            >
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </AddNewsStyles>
  );
}
