import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import manager from "./../../assets/img/manager.jpg";

const ManagementStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    position: relative;
    margin: 0px 50px;
    max-width: 1200px;
    height: 100%;
    width: 100%;
    padding: 40px 0px;

    @media (max-width: 767.98px) {
      margin: 0px 30px;
    }

    @media (max-width: 479.98px) {
      margin: 0px 20px;
    }
  }

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  .block {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header-data {
      width: 100%;
      display: flex;
      gap: 20px;

      img {
        max-height: 300px;
        height: 100%;
      }

      .main-data {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h3 {
          font-size: 18px;
          font-family: "Montserrat", sans-serif;
          line-height: 130%;
          font-weight: bold;
        }
        p {
          font-family: "Montserrat", sans-serif;
          line-height: 130%;
        }
      }
    }
    .body-data {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .body-part {
        display: flex;
        flex-direction: column;
        gap: 6px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            font-family: "Montserrat", sans-serif;
            line-height: 130%;
            text-align: justify;
          }
        }

        p {
          font-family: "Montserrat", sans-serif;
          line-height: 130%;
          text-align: justify;
        }
      }

      .data {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
`;

const managements = [
  {
    photo:
      "https://firebasestorage.googleapis.com/v0/b/arcticconreact.appspot.com/o/images%2Fmanagements%2F%D0%91%D1%8B%D0%BB%D0%B8%D0%BD%D0%BA%D0%B8%D0%BD%20%D0%9D.%D0%90..jpg?alt=media&token=36b9c8bc-21f0-4a39-b512-f25f3213cd90",
    name: "Былинкин Николай Александрович",
    position: "Генеральный директор",
    birthDay: null,
    education: [
      "Окончил Российский государственный  университет им И.М. Губкина, факультет проектирования, сооружения и эксплуатации систем  трубопроводного транспорта и Финансовый университет при правительстве РФ, по специальности «Финансы и кредит».",
    ],
    carier: [
      "Имеет 15 – летний опыт в области создания и управления бизнес-структурами.",
      "Успешный опыт в реализации крупных  проектов  для энергетической отрасли с разнообразным портфелем клиентов, включая ведущие нефтегазодобывающие компании.",
    ],
    awards: null,
  },
  {
    photo:
      "https://firebasestorage.googleapis.com/v0/b/arcticconreact.appspot.com/o/images%2Fmanagements%2F%D0%91%D1%8B%D0%BB%D0%B8%D0%BD%D0%BA%D0%B8%D0%BD%D0%B0%20%D0%9C.%D0%90..jpg?alt=media&token=69e9e4f7-1a27-4cd5-b6d9-0c239b17815c",
    name: "Былинкина Марина Александровна",
    position:
      "Заместитель генерального директора по вопросам экономики и финансов",
    birthDay: null,
    education: [
      "Финансовая академия при правительстве РФ, финансовый менеджмент.",
    ],
    carier: [
      "Занимала должность финансового директора в компаниях по различным отраслевыми направлениям:  Девелопмент, Производственная деятельность, Банковский сектор, поставки оборудования для Топливно-энергетического сектора",
      "Трудовой стаж 18 лет",
    ],
    awards: null,
  },
  {
    photo:
      "https://firebasestorage.googleapis.com/v0/b/arcticconreact.appspot.com/o/images%2Fmanagements%2F%D0%A2%D1%8F%D0%BD%20%D0%90.%D0%9B..jpg?alt=media&token=2e5e204d-2b98-46b0-bea4-1b8405caa1ba",
    name: "Тян Андрей Львович",
    position: "Исполнительный директор",
    birthDay: null,
    education: [
      "РГУ нефти и газа им. И.М. Губкина по специальности «Сооружение и ремонт газонефтепроводов и газонефтехранилищ». Далее Финансовый университет при правительстве РФ по специальности «Финансовый менеджмент».",
    ],
    carier: [
      "Более 11 лет опыта в сфере комплектации и строительства нефтегазовых объектов, в том числе ТС «Восточная Сибирь - Тихий Океан» (ВСТО-I, ВСТО-II),  ТС «Заполярье-НПС «Пурпе», «Каспийский Трубопроводный Консорциум», «Установка комплексной подготовки газа на Средневилюйском ГКМ».",
    ],
    awards: null,
  },
  {
    photo:
      "https://firebasestorage.googleapis.com/v0/b/arcticconreact.appspot.com/o/images%2Fmanagements%2F%D0%97%D0%B0%D0%B9%D1%86%D0%B5%D0%B2%D0%B0%20%D0%9C.%D0%9E..jpg?alt=media&token=4ff00dc5-c2a0-4b1f-9cc8-66172f14b636",
    name: "Зайцева Маргарита Олеговна",
    position:
      "Заместитель финансового директора по экономике и бухгалтерскому учету.",
    birthDay: null,
    education: [
      "Окончила с отличием факультет «Финансы и Кредит» Московского Университета Потребительской Кооперации (РУК) по специальности «Финансовый Менеджмент».",
    ],
    carier: [
      "Более 15 лет стажа на руководящих должностях в средних и крупных компаниях.",
      "За период трудовой деятельности принимала участие в реализации проектов по внедрению управленческого учета, постановке бюджетирования и стратегического планирования, автоматизации учета. Разрабатывала концепции ведения бухгалтерского учета в рамках изменения законодательства и импортозамещения.",
    ],
    awards: null,
  },
  {
    photo:
      "https://firebasestorage.googleapis.com/v0/b/arcticconreact.appspot.com/o/images%2Fmanagements%2F%D0%95%D1%84%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%9E.%D0%90..jpg?alt=media&token=dade069b-76a5-42e4-97e3-4a2406525c81",
    name: "Ефременкова Ольга Алексеевна",
    position: "Руководитель отдела закупок",
    birthDay: null,
    education: [
      "Московский финансово-юридический университет. Экономический факультет. Специальность Финансы и Кредит.",
    ],
    carier: [
      "Опыт работы в закупках более 13 лет. Долгосрочное сотрудничество с крупными российскими и зарубежными производителями. Широкая география поставок промышленного электрооборудования на крупные объекты энергетической и нефтегазовой отрасли.",
    ],
    awards: null,
  },
];

export default function Management() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <ManagementStyles>
      <div className="container">
        <h1>Руководство</h1>
        {managements.map((person, index) => {
          return (
            <div className="block" key={index}>
              <div className="header-data">
                <img src={person.photo} />
                <div className="body-data">
                  <div className="main-data">
                    <h3>{person.name}</h3>
                    <p>{person.position}</p>
                  </div>
                  <div className="data">
                    {person.birthDay == null ? (
                      ""
                    ) : (
                      <div className="body-part birthday">
                        <p>
                          <b>Год рождения: </b>
                          {person.birthDay}
                        </p>
                      </div>
                    )}
                    {person.education == null ? (
                      ""
                    ) : (
                      <div className="body-part education">
                        <p>
                          <b>Образование:</b>
                        </p>
                        <ul>
                          {person.education.map((place, index) => {
                            return <li key={index}>{place}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {person.carier == null ? (
                      ""
                    ) : (
                      <div className="body-part cerier">
                        <p>
                          <b>Карьера:</b>
                        </p>
                        {person.carier.map((place, index) => {
                          return <p key={index}>{place}</p>;
                        })}
                      </div>
                    )}
                    {person.awards == null ? (
                      ""
                    ) : (
                      <div className="body-part">
                        <p>
                          <b>Награды</b>
                        </p>
                        {person.awards.map((item, index) => {
                          return <p key={index}>{item}</p>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="block">
          <div className="header-data">
            <img src={manager} />
            <div className="main-data">
              <h3>Бущан Никита</h3>
              <p>Веб разработчик</p>
            </div>
          </div>
          <div className="body-data">
            <div className="body-part">
              <p>
                <b>Год рождения: </b>2023
              </p>
            </div>
            <div className="body-part">
              <p>
                <b>Образование:</b>
              </p>
              <ul>
                <li>1990 год - университет</li>
                <li>1980 год - школа</li>
              </ul>
            </div>
            <div className="body-part">
              <p>
                <b>Карьера:</b>
              </p>
              <p>Проработал 10 лет в Arctic Energostroy.</p>
            </div>
            <div className="body-part">
              <p>
                <b>Награды</b>
              </p>
              <p>Заслуга за долгий срок работы</p>
            </div>
          </div>
        </div> */}
      </div>
    </ManagementStyles>
  );
}
