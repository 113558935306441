import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ServiceStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-height: 200px;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

  .image {
    max-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .info {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    h2 {
      width: 100%;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      line-height: 120%;
      font-style: normal;
    }
    p {
      width: 100%;
      text-align: center;
      line-height: 120%;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 999;
  }
`;

export default function Service({ service, remove, edit }) {
    return (
        <ServiceStyles>
            <div className="info">
                <h2>{service.name}</h2>
                <p>{service.title}</p>
            </div>
            <div className="buttons">
                <Fab
                    className="edit-button"
                    color="primary"
                    size="small"
                    onClick={() => {
                        edit(service);
                    }}
                >
                    <EditIcon />
                </Fab>
                <Fab
                    className="delete-button"
                    color="secondary"
                    size="small"
                    onClick={() => {
                        remove(service);
                    }}
                >
                    <DeleteIcon />
                </Fab>
            </div>
        </ServiceStyles>
    );
}
