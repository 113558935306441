import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteFile } from "../../../../firebase/FileManager";
import { storage, updateNews } from "../../../../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { FBFileManager } from "../../../../firebase/FileManager";

const EditNewsStyles = styled.div`
  .edit-news {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 30px;
      h1 {
        height: fit-content;
      }
    }

    form {
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      .img-preview {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 200px;
          width: auto;
        }
      }

      .full {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }

      .select-image {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .photo-controller-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      .controller-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      .action-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
`;

export default function EditNews({ state, setSection, _news }) {
  const [news, setNews] = useState(_news);
  const [imageUpload, setImageUpload] = useState(null);
  const [open, setOpen] = React.useState(false);

  const fileManager = new FBFileManager();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setNews((news) => ({
      ...news,
      ...value,
    }));
  };

  async function updateNewsImg(news) {
    let newUrl = null;
    await fileManager.deleteFile(news.img).then(async () => {
      newUrl = await fileManager.uploadImage(imageUpload, "news");
    });

    return newUrl;
  }

  async function removeNewsImage() {
    var fileUrl = imageUpload ?? news.img;

    if (fileUrl == null) {
      return null;
    }

    var result = await fileManager.deleteFile(fileUrl);

    handleClose();

    return result;
  }

  async function updateNewsFile() {
    if (imageUpload != null) {
      await updateNewsImg(news).then((url) => {
        news.img = url;
      });
    }

    await updateNews(news).then(() => {
      var newsIndex = state.news.findIndex((x) => x.id === news.id);
      state.news[newsIndex] = news;
      setSection({ action: "Новости" });
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EditNewsStyles>
      {news != null ? (
        <div className="edit-news">
          <div className="title">
            <h1>Редактирование новости</h1>
          </div>
          <form>
            <TextField
              id="title"
              label="Заголовок новости"
              defaultValue={news.title}
              variant="outlined"
              onChange={(e) => handleChange({ title: e.target.value })}
            />
            <TextField
              id="short"
              label="Короткое описание"
              defaultValue={news.short}
              multiline
              rows={4}
              variant="outlined"
              onChange={(e) => handleChange({ short: e.target.value })}
            />
            <h2>Текст новости:</h2>
            {Array.isArray(news.full) ? (
              <div className="full">
                {news.full.map((paragraph, index) => {
                  return (
                    <TextField
                      fullWidth
                      key={index}
                      label="Параграф"
                      defaultValue={paragraph}
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        news.full[index] = e.target.value;
                        let list = [...news.full];
                        handleChange({ full: list });
                      }}
                    />
                  );
                })}
                <div className="controller-buttons">
                  <Button
                    sx={{ maxWidth: "30px" }}
                    onClick={() => {
                      if (news.full.length == 0) return;
                      let list = [...news.full];
                      list.pop();
                      handleChange({ full: list });
                    }}
                    variant="contained"
                  >
                    -
                  </Button>
                  <Button
                    sx={{ maxWidth: "30px" }}
                    onClick={() => {
                      let list = [...news.full];
                      list.push("");
                      handleChange({ full: list });
                    }}
                    variant="contained"
                  >
                    +
                  </Button>
                </div>
              </div>
            ) : (
              <TextField
                id="full"
                label="Текст"
                defaultValue={news.full}
                multiline
                rows={10}
                variant="outlined"
                onChange={(e) => handleChange({ full: e.target.value })}
              />
            )}
            {news.img !== null && imageUpload === null ? (
              <div className="img-preview">
                <img src={news.img} />
              </div>
            ) : (
              ""
            )}

            {imageUpload === null ? "" : `Загруженно фото: ${imageUpload.name}`}

            <div className="photo-controller-button">
              <Button variant="contained" component="label">
                {news.img === null ? "Загрузить фото" : "Обновить фото"}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e) => {
                    setImageUpload(e.target.files[0]);
                  }}
                />
              </Button>
              {news.img === null ? (
                ""
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickOpen}
                >
                  Удалить фото
                </Button>
              )}
            </div>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Удаление изображения новости"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Изображение которое вы хотите не подлежит восстановлению. Вы действиветльно хотите удалить изображение?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant={"contained"} onClick={handleClose}>Отменить удаление</Button>
                <Button variant={"contained"} color="error" onClick={removeNewsImage} autoFocus>
                  Да, удалить изображение
                </Button>
              </DialogActions>
            </Dialog>

            <div className="action-buttons">
              <Button
                onClick={() => setSection({ action: "Новости" })}
                variant="contained"
              >
                Отменить
              </Button>
              <Button onClick={updateNewsFile} variant={"contained"}>
                Сохранить изменения
              </Button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </EditNewsStyles>
  );
}
